import React from 'react';
// import '../../assets/stylesheets/test.scss'

class Hero extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){

        return (    
            <div>

                <img src={this.props.acf.background.url} />
                {/* {this.props.acf.acf_fc_layout} */}
                {/* <div dangerouslySetInnerHTML={{__html: this.props.acf.text}} /> */}
            </div>
        )
    }
}

export default Hero