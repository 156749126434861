import React from 'react';
class ContentBlock3x3 extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        let acf = this.props.acf

        return (
            <span>
            <div className="contentBlock three container">
                {acf.content_block_3x3_section_title && (
                    <h2 className="contentBlock__title">{acf.content_block_3x3_section_title}</h2>
                )}

                {acf.content_block_3x3_section_description && (
                    <span dangerouslySetInnerHTML={{__html: acf.content_block_3x3_section_description }} />
                )}
                <div className="contentBlock__flex-wrap">
                    {acf.content_block_3x3.map(content_block_3x3=>{
                        return (
                        <div className="contentBlock__container">
                            <img key={content_block_3x3.image.url} className="contentBlock__img" src={content_block_3x3.image.url} />
                            <div className="contentBlock__content-box">
                                <a className="contentBlock__button btnn" href={content_block_3x3.button_link.url}>{content_block_3x3.button_link.title}</a>
                            </div>
                        </div>
                        )
                    })}
                </div>    
            </div>
            </span>
        )
    }
}

export default ContentBlock3x3