import { connect } from 'react-redux';
import Footer from './index';
import { fetchPosts } from '../../actions/post_actions'

const mapStateToProps = (state) => {
    return {
        footer: Object.values(state.humane.footer)[0],
    };
};

const mapDispatchToProps = dispatch => ({
    fetchPosts: (slug)=>dispatch(fetchPosts(slug))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
