import React, { useState } from 'react';

const StepThree = ({ formData, handleChange, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});

  const validateStepThree = () => {
    let tempErrors = {};
    let isValid = true;

    if (
      !formData.do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter
    ) {
      tempErrors[
        'do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter'
      ] = 'Please select yes or no';
      isValid = false;
    }

    if (
      formData.do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter ===
        'yes' &&
      formData.if_yes_please_explain.trim() === ''
    ) {
      tempErrors['if_yes_please_explain'] = 'Please explain';
      isValid = false;
    }

    if (
      formData.how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable.trim() ===
      ''
    ) {
      tempErrors[
        'how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable'
      ] = 'Please explain';
      isValid = false;
    }

    if (
      formData.what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100.trim() ===
      ''
    ) {
      tempErrors[
        'what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100'
      ] = 'Please enter percent';
      isValid = false;
    }

    if (formData.amount_of_funds_requested_up_to_5000_maximum.trim() === '') {
      tempErrors['amount_of_funds_requested_up_to_5000_maximum'] =
        'Please enter amount of funds requested';
      isValid = false;
    }

    if (!formData.for_each_of) {
      tempErrors['for_each_of'] = 'Please select duration';
      isValid = false;
    }

    if (
      formData.please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant.trim() ===
      ''
    ) {
      tempErrors[
        'please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant'
      ] = 'Please enter calendar or fiscal years';
      isValid = false;
    }

    if (formData.total_grant_request.trim() === '') {
      tempErrors['total_grant_request'] = 'Please enter total grant request';
      isValid = false;
    }

    if (!formData.type_of_grant_request) {
      tempErrors['type_of_grant_request'] = 'Please select grant request type';
      isValid = false;
    }

    if (
      formData.anticipated_project_or_program_period_will_start_and_end.trim() ===
      ''
    ) {
      tempErrors['anticipated_project_or_program_period_will_start_and_end'] =
        'Please enter anticipated project or program period';
      isValid = false;
    }

    if (
      formData.total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected.trim() ===
      ''
    ) {
      tempErrors[
        'total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected'
      ] = 'Please enter total project budget';
      isValid = false;
    }

    if (formData.app_outcomes.trim() === '') {
      tempErrors['app_outcomes'] = 'Please enter outcomes';
      isValid = false;
    }

    if (formData.app_timeline.trim() === '') {
      tempErrors['app_timeline'] = 'Please enter timeline';
      isValid = false;
    }

    if (formData.app_capacity.trim() === '') {
      tempErrors['app_capacity'] = 'Please enter capacity';
      isValid = false;
    }

    if (formData.app_evaluate.trim() === '') {
      tempErrors['app_evaluate'] = 'Please enter evaluation';
      isValid = false;
    }

    if (formData.app_grant_why.trim() === '') {
      tempErrors['app_grant_why'] = 'Please enter grant why';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStepThree()) {
      nextStep();
    } else {
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section3">
      <h1>Women’s Shelters Grant — Step 3 </h1>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_13_do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter"
          >
            13. Do you currently conduct fundraising activities specifically
            relating to the housing of pets at your shelter?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter"
                id="freeform_13_do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter_1"
                value="yes"
                onChange={handleChange}
                checked={
                  formData.do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter ===
                  'yes'
                }
              />
              <label htmlFor="freeform_13_do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter"
                id="freeform_13_do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter_2"
                value="no"
                onChange={handleChange}
                checked={
                  formData.do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter ===
                  'no'
                }
              />
              <label htmlFor="freeform_13_do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter_2">
                No
              </label>
            </li>
          </ul>
          {errors.do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter && (
            <div className="error">
              {
                errors.do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_please_explain"
          >
            If yes, please explain.
          </label>

          <textarea
            name="if_yes_please_explain"
            cols="50"
            rows="6"
            id="freeform_if_yes_please_explain"
            onChange={handleChange}
            value={formData.if_yes_please_explain}
          ></textarea>
          {errors.if_yes_please_explain && (
            <div className="error">{errors.if_yes_please_explain}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_14_how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable"
          >
            14. How do you educate staff and residents about the purpose of
            housing and caring for pets at your shelter? Include examples where
            applicable.
          </label>

          <textarea
            name="how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable"
            cols="50"
            rows="18"
            id="freeform_14_how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable"
            onChange={handleChange}
            value={
              formData.how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable
            }
          ></textarea>
          {errors.how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable && (
            <div className="error">
              {
                errors.how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable
              }
            </div>
          )}

          <label
            className="field_label"
            htmlFor="freeform_15_what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100"
          >
            15. What percent of pet care at your shelter is carried out by
            residents, staff, volunteers, and others? (E.g., 25% Volunteer, 75%
            Staff).
          </label>

          <input
            type="text"
            name="what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100"
            id="freeform_15_what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100"
            maxLength="150"
            onChange={handleChange}
            value={
              formData.what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100
            }
          />
          {errors.what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100 && (
            <div className="error">
              {
                errors.what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_16_amount_of_funds_requested_up_to_5000_maximum"
          >
            16. Amount of funds requested (up to $5,000 maximum)
          </label>

          <input
            type="text"
            name="amount_of_funds_requested_up_to_5000_maximum"
            id="freeform_16_amount_of_funds_requested_up_to_5000_maximum"
            maxLength="150"
            onChange={handleChange}
            value={formData.amount_of_funds_requested_up_to_5000_maximum}
          />
          {errors.amount_of_funds_requested_up_to_5000_maximum && (
            <div className="error">
              {errors.amount_of_funds_requested_up_to_5000_maximum}
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_for_each_of">
            for each of
          </label>

          <select name="for_each_of" id="freeform_for_each_of">
            <option value="1 year">1 year</option>
            <option value="2 years">2 years</option>
            <option value="3 years">3 years</option>
          </select>
          {errors.for_each_of && (
            <div className="error">{errors.for_each_of}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant"
          >
            Please indicate the Calendar or Fiscal Year(s) for which you will
            use this grant:
          </label>

          <input
            type="text"
            name="please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant"
            id="freeform_please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant"
            maxLength="150"
            onChange={handleChange}
            value={
              formData.please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant
            }
          />
          {errors.please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant && (
            <div className="error">
              {
                errors.please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_total_grant_request">
            Total Grant Request:
          </label>

          <input
            type="text"
            name="total_grant_request"
            id="freeform_total_grant_request"
            maxLength="150"
            onChange={handleChange}
            value={formData.total_grant_request}
          />
          {errors.total_grant_request && (
            <div className="error">{errors.total_grant_request}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_type_of_grant_request"
          >
            Type of grant request:
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="type_of_grant_request"
                id="freeform_type_of_grant_request_1"
                value="Pet-related operating costs or pet-related capital support"
                onChange={handleChange}
                checked={
                  formData.type_of_grant_request ===
                  'Pet-related operating costs or pet-related capital support'
                }
              />
              <label htmlFor="freeform_type_of_grant_request_1">
                Pet-related operating costs or pet-related capital support
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="type_of_grant_request"
                id="freeform_type_of_grant_request_2"
                value="Pet-related special project"
                onChange={handleChange}
                checked={
                  formData.type_of_grant_request ===
                  'Pet-related special project'
                }
              />
              <label htmlFor="freeform_type_of_grant_request_2">
                Pet-related special project
              </label>
            </li>
          </ul>
          {errors.type_of_grant_request && (
            <div className="error">{errors.type_of_grant_request}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <p>
            <strong>
              17. How will the funds be used? Provide a detailed proposed use of
              funds (if awarded). Include the following information, if
              applicable.
            </strong>
          </p>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_a_anticipated_project_or_program_period_will_start_and_end"
          >
            A. Anticipated project or program period will start and end:
          </label>

          <input
            type="text"
            name="anticipated_project_or_program_period_will_start_and_end"
            id="freeform_a_anticipated_project_or_program_period_will_start_and_end"
            maxLength="150"
            onChange={handleChange}
            value={
              formData.anticipated_project_or_program_period_will_start_and_end
            }
          />
          {errors.anticipated_project_or_program_period_will_start_and_end && (
            <div className="error">
              {errors.anticipated_project_or_program_period_will_start_and_end}
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_b_total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected"
          >
            B. Total project budget, include anticipated sources of income and
            expected expenditures. C. Other funding sources and amounts received
            or expected.
          </label>

          <textarea
            name="total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected"
            cols="50"
            rows="22"
            id="freeform_b_total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected"
            onChange={handleChange}
            value={
              formData.total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected
            }
          ></textarea>
          {errors.total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected && (
            <div className="error">
              {
                errors.total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_outcomes">
            What are the outcomes you want to achieve with this grant?
          </label>

          <textarea
            name="app_outcomes"
            cols="50"
            rows="6"
            id="freeform_app_outcomes"
            onChange={handleChange}
            value={formData.app_outcomes}
          ></textarea>
          {errors.app_outcomes && (
            <div className="error">{errors.app_outcomes}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_timeline">
            What is your plan for accomplishing the outcomes and your project
            timeline?
          </label>

          <textarea
            name="app_timeline"
            cols="50"
            rows="6"
            id="freeform_app_timeline"
            onChange={handleChange}
            value={formData.app_timeline}
          ></textarea>
          {errors.app_timeline && (
            <div className="error">{errors.app_timeline}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_capacity">
            What is the capacity for your organization to carry out the plan?
          </label>
          <textarea
            name="app_capacity"
            cols="50"
            rows="6"
            id="freeform_app_capacity"
            onChange={handleChange}
            value={formData.app_capacity}
          ></textarea>
          {errors.app_capacity && (
            <div className="error">{errors.app_capacity}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_evaluate">
            How will you evaluate the success and effectiveness of the program?
          </label>

          <textarea
            name="app_evaluate"
            cols="50"
            rows="6"
            id="freeform_app_evaluate"
            onChange={handleChange}
            value={formData.app_evaluate}
          ></textarea>
          {errors.app_evaluate && (
            <div className="error">{errors.app_evaluate}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_grant_why">
            Why should the AKC Humane Fund support your project/organization?
          </label>

          <textarea
            name="app_grant_why"
            cols="50"
            rows="6"
            id="freeform_app_grant_why"
            onChange={handleChange}
            value={formData.app_grant_why}
          ></textarea>
          {errors.app_grant_why && (
            <div className="error">{errors.app_grant_why}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>

          <a href className="btn bg-black" onClick={prevStep} id="3">
            Back
          </a>
        </div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>
          <a href className="btn pull-right" id="3" onClick={handleNext}>
            Continue To Part 4
          </a>
        </div>
      </div>
    </span>
  );
};

export default StepThree;
