import React, { useState } from 'react';
import $ from 'jquery';

const StepThree = ({
  formData,
  handleChange,
  prevStep,
  setIsFormSubmitted,
}) => {
  const [errors, setErrors] = useState({});

  const validateStepThree = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.app_total_budget.trim() === '') {
      tempErrors['app_total_budget'] = 'Please enter the total project budget';
      isValid = false;
    }

    if (formData.app_funding_sources.trim() === '') {
      tempErrors['app_funding_sources'] =
        'Please enter other funding sources or enter';
      isValid = false;
    }

    if (formData.app_timeline.trim() === '') {
      tempErrors['app_timeline'] = 'Please enter your project timeline';
      isValid = false;
    }

    if (formData.app_outcomes.trim() === '') {
      tempErrors['app_outcomes'] = 'Please enter the outcomes';
      isValid = false;
    }

    if (formData.app_capacity.trim() === '') {
      tempErrors['app_capacity'] = 'Please enter the capacity';
      isValid = false;
    }

    if (formData.app_evaluate.trim() === '') {
      tempErrors['app_evaluate'] = 'Please enter how you will evaluate';
      isValid = false;
    }

    if (formData.app_breed_rescue.trim() === '') {
      tempErrors['app_breed_rescue'] =
        'Please enter how your organization is working';
      isValid = false;
    }

    if (formData.number_rescued.trim() === '') {
      tempErrors['number_rescued'] = 'Please enter the number of dogs rescued';
      isValid = false;
    }

    if (formData.app_grant_use.trim() === '') {
      tempErrors['app_grant_use'] = 'Please enter how the grant will be used';
      isValid = false;
    }

    if (formData.app_grant_why.trim() === '') {
      tempErrors['app_grant_why'] =
        'Please enter why the AKC Humane Fund should support your project/organization';
      isValid = false;
    }

    if (formData.app_ref_name1.trim() === '') {
      tempErrors['app_ref_name1'] = 'Please enter the full name';
      isValid = false;
    }

    if (formData.app_ref_address1.trim() === '') {
      tempErrors['app_ref_address1'] = 'Please enter the street address';
      isValid = false;
    }

    if (formData.app_ref_city1.trim() === '') {
      tempErrors['app_ref_city1'] = 'Please enter the city';
      isValid = false;
    }

    if (formData.app_ref_state1.trim() === '') {
      tempErrors['app_ref_state1'] = 'Please enter the state';
      isValid = false;
    }

    if (formData.app_ref_zip1.trim() === '') {
      tempErrors['app_ref_zip1'] = 'Please enter the zip code';
      isValid = false;
    }

    if (formData.app_ref_phone1.trim() === '') {
      tempErrors['app_ref_phone1'] = 'Please enter the phone number';
      isValid = false;
    }

    if (formData.app_ref_name2.trim() === '') {
      tempErrors['app_ref_name2'] = 'Please enter the full name';
      isValid = false;
    }

    if (formData.app_ref_address2.trim() === '') {
      tempErrors['app_ref_address2'] = 'Please enter the street address';
      isValid = false;
    }

    if (formData.app_ref_city2.trim() === '') {
      tempErrors['app_ref_city2'] = 'Please enter the city';
      isValid = false;
    }

    if (formData.app_ref_state2.trim() === '') {
      tempErrors['app_ref_state2'] = 'Please enter the state';
      isValid = false;
    }

    if (formData.app_ref_zip2.trim() === '') {
      tempErrors['app_ref_zip2'] = 'Please enter the zip code';
      isValid = false;
    }

    if (formData.app_ref_phone2.trim() === '') {
      tempErrors['app_ref_phone2'] = 'Please enter the phone number';
      isValid = false;
    }

    if (formData.app_ref_name3.trim() === '') {
      tempErrors['app_ref_name3'] = 'Please enter the full name';
      isValid = false;
    }

    if (formData.app_ref_address3.trim() === '') {
      tempErrors['app_ref_address3'] = 'Please enter the street address';
      isValid = false;
    }

    if (formData.app_ref_city3.trim() === '') {
      tempErrors['app_ref_city3'] = 'Please enter the city';
      isValid = false;
    }

    if (formData.app_ref_state3.trim() === '') {
      tempErrors['app_ref_state3'] = 'Please enter the state';
      isValid = false;
    }

    if (formData.app_ref_zip3.trim() === '') {
      tempErrors['app_ref_zip3'] = 'Please enter the zip code';
      isValid = false;
    }

    if (formData.app_ref_phone3.trim() === '') {
      tempErrors['app_ref_phone3'] = 'Please enter the phone number';
      isValid = false;
    }

    if (
      formData.is_the_organization_party_to_any_claim_or_lawsuit.trim() === ''
    ) {
      tempErrors['is_the_organization_party_to_any_claim_or_lawsuit'] =
        'Please select an option';
      isValid = false;
    }

    if (
      formData.are_there_any_business_or_judgment_liens_against_the_organization.trim() ===
      ''
    ) {
      tempErrors[
        'are_there_any_business_or_judgment_liens_against_the_organization'
      ] = 'Please select an option';
      isValid = false;
    }

    if (
      formData.does_the_organization_have_any_debts_outstanding.trim() === ''
    ) {
      tempErrors['does_the_organization_have_any_debts_outstanding'] =
        'Please select an option';
      isValid = false;
    }

    if (
      formData.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements.trim() ===
      ''
    ) {
      tempErrors[
        'is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements'
      ] = 'Please select an option';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStepThree()) {
      const formDataToSend = new FormData();

      // Append fields
      for (const key in formData) {
        if (Array.isArray(formData[key])) {
          if (key === 'app_grant_type') {
            formDataToSend.append(key, formData[key].join(', '));
          } else {
            formData[key].forEach((item) =>
              formDataToSend.append(`${key}[]`, item)
            );
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }

      // Append action
      formDataToSend.append('action', 'handle_breed_form_submit_ajax');

      $.ajax({
        type: 'POST',
        url: '/wp-admin/admin-ajax.php',
        dataType: 'json',
        data: formDataToSend,
        cache: false,
        processData: false,
        contentType: false,
        success: function (response) {
          setIsFormSubmitted(true);
        },
        error: function (error) {
          console.log(error);
        },
      });
    } else {
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section3">
      <h1>Breed Rescue Grant Application — Step 3 </h1>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h3>Professional References</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_total_budget">
            Include the total project budget, including all anticipated sources
            of income and projected expenditures.
          </label>

          <textarea
            name="app_total_budget"
            cols="50"
            rows="6"
            id="freeform_app_total_budget"
            onChange={handleChange}
            value={formData.app_total_budget}
          ></textarea>

          {errors.app_total_budget && (
            <div className="error">{errors.app_total_budget}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_funding_sources">
            Other funding sources and amounts received or expected for this
            project
          </label>

          <textarea
            name="app_funding_sources"
            cols="50"
            rows="6"
            id="freeform_app_funding_sources"
            onChange={handleChange}
            value={formData.app_funding_sources}
          ></textarea>

          {errors.app_funding_sources && (
            <div className="error">{errors.app_funding_sources}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_timeline">
            What is your plan for accomplishing the outcomes and your project
            timeline?
          </label>

          <textarea
            name="app_timeline"
            cols="50"
            rows="6"
            id="freeform_app_timeline"
            onChange={handleChange}
            value={formData.app_timeline}
          ></textarea>

          {errors.app_timeline && (
            <div className="error">{errors.app_timeline}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_outcomes">
            What are the outcomes you want to achieve with this grant?
          </label>

          <textarea
            name="app_outcomes"
            cols="50"
            rows="6"
            id="freeform_app_outcomes"
            onChange={handleChange}
            value={formData.app_outcomes}
          ></textarea>

          {errors.app_outcomes && (
            <div className="error">{errors.app_outcomes}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_capacity">
            What is the capacity for your organization to carry out the plan?
          </label>

          <textarea
            name="app_capacity"
            cols="50"
            rows="6"
            id="freeform_app_capacity"
            onChange={handleChange}
            value={formData.app_capacity}
          ></textarea>

          {errors.app_capacity && (
            <div className="error">{errors.app_capacity}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_evaluate">
            How will you evaluate the success and effectiveness of the program?
          </label>

          <textarea
            name="app_evaluate"
            cols="50"
            rows="6"
            id="freeform_app_evaluate"
            onChange={handleChange}
            value={formData.app_evaluate}
          ></textarea>

          {errors.app_evaluate && (
            <div className="error">{errors.app_evaluate}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h3>General Questions for All Applicants</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_breed_rescue">
            How is your organization working to achieve breed rescue?
          </label>

          <textarea
            name="app_breed_rescue"
            cols="50"
            rows="6"
            id="freeform_app_breed_rescue"
            onChange={handleChange}
            value={formData.app_breed_rescue}
          ></textarea>

          {errors.app_breed_rescue && (
            <div className="error">{errors.app_breed_rescue}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_number_rescued">
            Since the organization’s inception, cite the number of dogs rescued
            each year, including intakes, placements, transfers, and
            euthanasias.
          </label>

          <textarea
            name="number_rescued"
            cols="50"
            rows="6"
            id="freeform_number_rescued"
            onChange={handleChange}
            value={formData.number_rescued}
          ></textarea>

          {errors.number_rescued && (
            <div className="error">{errors.number_rescued}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_grant_use">
            How will the grant be used, specifically?
          </label>

          <textarea
            name="app_grant_use"
            cols="50"
            rows="6"
            id="freeform_app_grant_use"
            onChange={handleChange}
            value={formData.app_grant_use}
          ></textarea>

          {errors.app_grant_use && (
            <div className="error">{errors.app_grant_use}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_grant_why">
            Why should the AKC Humane Fund support your project/organization?
          </label>

          <textarea
            name="app_grant_why"
            cols="50"
            rows="6"
            id="freeform_app_grant_why"
            onChange={handleChange}
            value={formData.app_grant_why}
          ></textarea>

          {errors.app_grant_why && (
            <div className="error">{errors.app_grant_why}</div>
          )}
          <label
            className="field_label"
            htmlFor="freeform_please_attach_the_recent_financial_statements_for_your_organization"
          >
            Please attach the recent financial statements for your organization.
          </label>

          <p></p>
          <ul id="upload94"></ul>
          <p></p>
          <input
            type="file"
            name="please_attach_the_recent_financial_statements_for_your_organization_1"
            id="freeform_please_attach_the_recent_financial_statements_for_your_organization0"
            onChange={handleChange}
            multiple
          />
          <input
            type="file"
            name="please_attach_the_recent_financial_statements_for_your_organization_2"
            id="freeform_please_attach_the_recent_financial_statements_for_your_organization1"
            onChange={handleChange}
            multiple
          />

          <label
            className="field_label"
            htmlFor="freeform_please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization"
          >
            Please attach the IRS Determination Letter confirming Section 501
            (c)(3) status of your organization.
          </label>

          <p></p>
          <ul id="upload95"></ul>
          <p></p>
          <input
            type="file"
            name="please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_1"
            id="freeform_please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization0"
            onChange={handleChange}
            multiple
          />
          <input
            type="file"
            name="please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_2"
            id="freeform_please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization1"
            onChange={handleChange}
            multiple
          />
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_letters_of_reference"
          >
            Please attach the Parent Club cover letter or Parent Club Letter of
            Recommendation.
          </label>

          <p></p>
          <ul id="upload110"></ul>
          <p></p>
          <input
            type="file"
            name="letters_of_reference_1"
            id="freeform_letters_of_reference0"
            onChange={handleChange}
            multiple
          />

          <input
            type="file"
            name="letters_of_reference_2"
            id="freeform_letters_of_reference1"
            onChange={handleChange}
            multiple
          />
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>
          <h3>Personal References</h3>
          List three (3) professional references (club officers, veterinarian,
          shelter operator, etc.)
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_ref_name1">
            Full Name
          </label>

          <input
            type="text"
            name="app_ref_name1"
            id="freeform_app_ref_name1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_name1}
          />

          {errors.app_ref_name1 && (
            <div className="error">{errors.app_ref_name1}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_ref_address1">
            Street Address
          </label>

          <input
            type="text"
            name="app_ref_address1"
            id="freeform_app_ref_address1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_address1}
          />

          {errors.app_ref_address1 && (
            <div className="error">{errors.app_ref_address1}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_city1">
            City
          </label>

          <input
            type="text"
            name="app_ref_city1"
            id="freeform_app_ref_city1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_city1}
          />

          {errors.app_ref_city1 && (
            <div className="error">{errors.app_ref_city1}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_state1">
            State
          </label>

          <input
            type="text"
            name="app_ref_state1"
            id="freeform_app_ref_state1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_state1}
          />

          {errors.app_ref_state1 && (
            <div className="error">{errors.app_ref_state1}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_zip1">
            Zip Code
          </label>

          <input
            type="text"
            name="app_ref_zip1"
            id="freeform_app_ref_zip1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_zip1}
          />

          {errors.app_ref_zip1 && (
            <div className="error">{errors.app_ref_zip1}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_app_phone">
            Phone Number
          </label>

          <input
            type="text"
            name="app_ref_phone1"
            id="freeform_app_phone"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_phone1}
          />

          {errors.app_ref_phone1 && (
            <div className="error">{errors.app_ref_phone1}</div>
          )}
        </div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_ref_name1">
            Full Name
          </label>

          <input
            type="text"
            name="app_ref_name2"
            id="freeform_app_ref_name1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_name2}
          />

          {errors.app_ref_name2 && (
            <div className="error">{errors.app_ref_name2}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_street_address2">
            Street Address
          </label>

          <input
            type="text"
            name="app_ref_address2"
            id="freeform_app_street_address2"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_address2}
          />

          {errors.app_ref_address2 && (
            <div className="error">{errors.app_ref_address2}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_city2">
            City
          </label>

          <input
            type="text"
            name="app_ref_city2"
            id="freeform_app_city2"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_city2}
          />

          {errors.app_ref_city2 && (
            <div className="error">{errors.app_ref_city2}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_state2">
            State
          </label>

          <input
            type="text"
            name="app_ref_state2"
            id="freeform_app_state2"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_state2}
          />

          {errors.app_ref_state2 && (
            <div className="error">{errors.app_ref_state2}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_zip2">
            Zip Code
          </label>

          <input
            type="text"
            name="app_ref_zip2"
            id="freeform_app_ref_zip2"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_zip2}
          />

          {errors.app_ref_zip2 && (
            <div className="error">{errors.app_ref_zip2}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_app_phone2">
            Phone Number
          </label>

          <input
            type="text"
            name="app_ref_phone2"
            id="freeform_app_phone2"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_phone2}
          />

          {errors.app_ref_phone2 && (
            <div className="error">{errors.app_ref_phone2}</div>
          )}
        </div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_ref_name3">
            Full Name
          </label>

          <input
            type="text"
            name="app_ref_name3"
            id="freeform_app_ref_name3"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_name3}
          />
          {errors.app_ref_name3 && (
            <div className="error">{errors.app_ref_name3}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_street_address3">
            Street Address
          </label>
          <input
            type="text"
            name="app_ref_address3"
            id="freeform_app_street_address3"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_address3}
          />

          {errors.app_ref_address3 && (
            <div className="error">{errors.app_ref_address3}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_city3">
            City
          </label>

          <input
            type="text"
            name="app_ref_city3"
            id="freeform_app_city3"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_city3}
          />
          {errors.app_ref_city3 && (
            <div className="error">{errors.app_ref_city3}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_state3">
            State
          </label>

          <input
            type="text"
            name="app_ref_state3"
            id="freeform_app_ref_state3"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_state3}
          />

          {errors.app_ref_state3 && (
            <div className="error">{errors.app_ref_state3}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_zip3">
            Zip Code
          </label>

          <input
            type="text"
            name="app_ref_zip3"
            id="freeform_app_ref_zip3"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_zip3}
          />

          {errors.app_ref_zip3 && (
            <div className="error">{errors.app_ref_zip3}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_app_ref_phone3">
            Phone Number
          </label>

          <input
            type="text"
            name="app_ref_phone3"
            id="freeform_app_ref_phone3"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_phone3}
          />
          {errors.app_ref_phone3 && (
            <div className="error">{errors.app_ref_phone3}</div>
          )}
        </div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>
          <h2>Additional Information</h2>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_is_the_organization_party_to_any_claim_or_lawsuit"
          >
            Is the organization party to any claim or lawsuit?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="is_the_organization_party_to_any_claim_or_lawsuit"
                id="freeform_is_the_organization_party_to_any_claim_or_lawsuit_1"
                onChange={handleChange}
                value="yes"
                checked={
                  formData.is_the_organization_party_to_any_claim_or_lawsuit ===
                  'yes'
                }
              />
              <label htmlFor="freeform_is_the_organization_party_to_any_claim_or_lawsuit_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="is_the_organization_party_to_any_claim_or_lawsuit"
                id="freeform_is_the_organization_party_to_any_claim_or_lawsuit_2"
                onChange={handleChange}
                value="no"
                checked={
                  formData.is_the_organization_party_to_any_claim_or_lawsuit ===
                  'no'
                }
              />
              <label htmlFor="freeform_is_the_organization_party_to_any_claim_or_lawsuit_2">
                No
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_are_there_any_business_or_judgment_liens_against_the_organization"
          >
            Are there any business or judgment liens against the organization?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="are_there_any_business_or_judgment_liens_against_the_organization"
                id="freeform_are_there_any_business_or_judgment_liens_against_the_organization_1"
                onChange={handleChange}
                value="yes"
                checked={
                  formData.are_there_any_business_or_judgment_liens_against_the_organization ===
                  'yes'
                }
              />
              <label htmlFor="freeform_are_there_any_business_or_judgment_liens_against_the_organization_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="are_there_any_business_or_judgment_liens_against_the_organization"
                id="freeform_are_there_any_business_or_judgment_liens_against_the_organization_2"
                onChange={handleChange}
                value="no"
                checked={
                  formData.are_there_any_business_or_judgment_liens_against_the_organization ===
                  'no'
                }
              />
              <label htmlFor="freeform_are_there_any_business_or_judgment_liens_against_the_organization_2">
                No
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_does_the_organization_have_any_debts_outstanding"
          >
            Does the organization have any debts outstanding?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="does_the_organization_have_any_debts_outstanding"
                id="freeform_does_the_organization_have_any_debts_outstanding_1"
                onChange={handleChange}
                value="yes"
                checked={
                  formData.does_the_organization_have_any_debts_outstanding ===
                  'yes'
                }
              />
              <label htmlFor="freeform_does_the_organization_have_any_debts_outstanding_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="does_the_organization_have_any_debts_outstanding"
                id="freeform_does_the_organization_have_any_debts_outstanding_2"
                onChange={handleChange}
                value="no"
                checked={
                  formData.does_the_organization_have_any_debts_outstanding ===
                  'no'
                }
              />
              <label htmlFor="freeform_does_the_organization_have_any_debts_outstanding_2">
                No
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements"
          >
            Is the organization an endorser or guarantor for any debts not
            listed on this Grant Application or the attached financial
            statements?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements"
                id="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_1"
                onChange={handleChange}
                value="yes"
                checked={
                  formData.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements ===
                  'yes'
                }
              />
              <label htmlFor="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements"
                id="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_2"
                onChange={handleChange}
                value="no"
                checked={
                  formData.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements ===
                  'no'
                }
              />
              <label htmlFor="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_2">
                No
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>
          By clicking on the “I agree and certify” button, below, I:
          <ol>
            <li>
              represent that I am duly authorized to execute and deliver this
              Grant Application on behalf of the organization;
            </li>

            <li>certify to the truth of my statements above.</li>
          </ol>
          Further, by clicking on the “I agree and certify” button, below, I
          agree and certify that I am authenticating the information provided as
          I would be with a written signature. Any misrepresentation on this
          application may result in the revocation of the grant.
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_"></label>
          <a type="submit" className="btn bg-black" onClick={prevStep}>
            Back
          </a>
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_"></label>
          <input
            type="submit"
            name="submit"
            defaultValue="I agree and certify"
            onClick={handleSubmit}
            className="btn pull-right"
          />
        </div>
      </div>
    </span>
  );
};

export default StepThree;
