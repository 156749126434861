import React, { Component, useEffect } from 'react'
import axios from 'axios';

export class NavMenu extends Component {
    constructor(props) {
        super(props);
    }

   state = {
       navMenu: [],
       isLoaded: false
   }

   componentDidMount () {
    this.props.fetchPosts("navMenu")
   }

   componentDidUpdate (prevProps) {
       if (prevProps.navMenu !== this.props.navMenu) { 
           this.setState({ 
               navMenu: this.props.navMenu[0],
               acf: this.props.navMenu[0].acf,
               isLoaded: true,
            }) 
        }
   }

   render () {
    let navMenus = this.state.navMenu
    let acf = this.state.acf

      return (
        <header>
            {/* Header */}

            {/* {acf && acf.logo_url && (
                <img src={acf.logo_url}  />
            )} */}

            {/* {navMenus && navMenus.map(navMenu =>
            <div key={navMenu.id}> 
                <h4>{navMenu.title.rendered}</h4>
                {navMenu.content.rendered}
            </div>
            )} */}
        </header>
        )
    }   
}
export default NavMenu
