import { useState } from 'react'
import Slick from 'react-slick'
import { memoize, merge } from 'lodash'

const memoizedMerge = memoize(merge)

const Slider = ({ credits, children, ...props }) => {
  // the default append dots function, if none is passed to slider

  const settings = memoizedMerge({
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,  
  }, props)


  return (
    <Slick {...settings}>
        {children}
    </Slick>
    )
}

export default Slider
