import React, { Component, useEffect } from 'react'
import $ from 'jquery';

export class SignUpForm extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        success: false
    }

    preventFormSubmission(e) {
        if (!e) return
        e.preventDefault();
        let $form = $(`#signUp`);
        let serializedData = $form.serialize();

        function responseReceived () {
            $(`#signUp`).hide()
            $('.SignUp__success').toggleClass('active')
        }

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php', 
            dataType: "json",
            data: {
                "action": 'handle_form_submit_ajax',
                "form": serializedData,
            },
            success: function( response ){ 
                responseReceived()
            },
            error: function(error){ 
                console.log(error) 
            }                
        }).then((response)=>{
        })
    }

    renderForm(){
        return (
            <form onSubmit={this.preventFormSubmission.bind(this)} id={`signUp`} >
                <input type="hidden" email="email" className="input pull-left required" placeholder="Email Address" required="" />
                <input type="email" name="email" className="input pull-left required email" placeholder="Email Address" required="" />           
                <input type="hidden" name="mailinglist[1]" value="y" checked="checked" />
                <input type="submit" name="submit" className="btn submit" value="Submit" />
            </form>
            )
    }

    render() {
        return (
            <div>
                {this.renderForm()}
                {this.state.success ? "" : this.preventFormSubmission()}
                <div className={"SignUp__success"}>
                    <h5>Thank You! Your Response Has Reen Received!</h5>
                </div>
            </div>
        )
    }
}

export default SignUpForm