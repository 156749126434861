import { RECEIVE_POSTS } from '../actions/post_actions'

export default (state = {}, action) => {
    Object.freeze(state)
    switch (action.type) {
        case RECEIVE_POSTS:
          if (action.postsData[0] && action.postsData[0].slug === "footer")
            return { footer: action.postsData }
        default:
            return state;
    }
}