import React, { Component, useEffect } from 'react';
import SignUp from './SignUp';
import BreedRescueGrant from './BreedRescueGrant/BreedRescueGrant';
import WomensRescueGrant from './WomensShelterGrant/WomensShelterGrant';
import MakeCorporateDonation from './MakeCorporateDonation';
import MakeLegacyGift from './MakeLegacyGift';
import LifeInsuranceDonation from './LifeInsuranceDonation';

export class FormHome extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    layouts: [],
    slug: this.props.slug,
  };

  render() {
    let acf = this.props.acf;
    let formOptions = acf.form_select_options;

    let formMap = {
      breedRescue: <BreedRescueGrant acf={acf} />,
      signUp: <SignUp acf={acf} />,
      womensGrant: <WomensRescueGrant acf={acf} />,
      corporateDonation: <MakeCorporateDonation acf={acf} />,
      makeLegacyGift: <MakeLegacyGift acf={acf} />,
      lifeInsurance: <LifeInsuranceDonation acf={acf} />,
    };

    return (
      <div>
        {formOptions.value == 'signUp' && (
          <div className="Forms__signUp">{formMap[formOptions.value]}</div>
        )}

        {formOptions.value == 'breedRescue' && (
          <div className="Forms__breedRescue">{formMap[formOptions.value]}</div>
        )}

        {formOptions.value == 'womensGrant' && (
          <div className="Forms__breedRescue">{formMap[formOptions.value]}</div>
        )}

        {formOptions.value == 'corporateDonation' && (
          <div className="Forms__corporateDonation">
            {formMap[formOptions.value]}
          </div>
        )}

        {formOptions.value == 'makeLegacyGift' && (
          <div className="Forms__makeLegacyGift">
            {formMap[formOptions.value]}
          </div>
        )}

        {formOptions.value == 'lifeInsurance' && (
          <div className="Forms__lifeInsuranceDonation">
            {formMap[formOptions.value]}
          </div>
        )}
      </div>
    );
  }
}

export default FormHome;
