import React, { useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

const WomensShelterGrant = ({ acf }) => {
  const [step, setStep] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    // step 1
    full_legal_name_of_applicant: '',
    app_legal_structure: '',
    employee_identification_number_ein: '',
    app_contact_name: '',
    app_contact_title: '',
    mailing_address: '',
    app_city: '',
    app_state: '',
    app_ref_zip1: '',
    app_phone: '',
    email: '',
    app_website: '',
    app_geography: '',
    app_year_founded: '',
    app_employees: '',
    app_volunteers: '',
    app_fiscal_year: '',
    is_your_organization: '',
    have_you_applied_for_a_grant_from_the_akc_humane_fund_previously: '',
    app_apply_year: '',
    were_you_awarded_a_grant_from_the_akc_humane_fund: '',
    if_yes_what_year_did_you_submit_an_application: '',
    // step 2
    what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document:
      '',
    what_is_the_overall_goal_and_mission_of_your_organization: '',
    list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years:
      '',
    in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only:
      '',
    how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants:
      '',
    how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants:
      '',
    do_you_charge_residents_a_fee_for_housing_their_pets: '',
    if_yes_what_is_the_fee_per_pet_for_how_long: '',
    how_do_you_house_pets_in_your_shelter: [],
    if_other_please_explain: '',
    do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing:
      '',
    do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter:
      '',
    if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization:
      '',
    // step 3
    do_you_currently_conduct_fundraising_activities_specifically_relating_to_the_housing_of_pets_at_your_shelter:
      '',
    if_yes_please_explain: '',
    how_do_you_educate_staff_and_residents_about_the_purpose_of_housing_and_caring_for_pets_at_your_shelter_include_examples_where_applicable:
      '',
    what_percent_of_pet_care_at_your_shelter_is_carried_out_by_residents_staff_volunteers_and_others_the_total_must_add_to_100:
      '',
    amount_of_funds_requested_up_to_5000_maximum: '',
    for_each_of: '1 year',
    please_indicate_the_calendar_or_fiscal_years_for_which_you_will_use_this_grant:
      '',
    total_grant_request: '',
    type_of_grant_request: '',
    anticipated_project_or_program_period_will_start_and_end: '',
    total_project_budget_include_anticipated_sources_of_income_and_expected_expenditures_c_other_funding_sources_and_amounts_received_or_expected:
      '',
    app_outcomes: '',
    app_timeline: '',
    app_capacity: '',
    app_evaluate: '',
    app_grant_why: '',

    // step 4
    is_the_organization_party_to_any_claim_or_lawsuit: '',
    if_yes_please_explain_2: '',
    are_there_any_business_or_judgment_liens_against_the_organization: '',
    if_yes_please_explain_again: '',
    does_the_organization_have_any_debts_outstanding: '',
    if_yes_please_explain_5: '',
    is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements:
      '',
    please_attach_the_recent_financial_statements_for_your_organization_1: [],
    please_attach_the_recent_financial_statements_for_your_organization_2: [],
    please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_1:
      [],
    please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_2:
      [],
    name_of_organization: '',
    date_submitted: '',
    email_subject: 'New Application - Womens Shelter Grant',
    email_applicant: 'Women’s Shelters Grant',
    
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, files } = e.target;

    if (files) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: Array.from(files),
      }));
    } else {
      const { value, type, checked } = e.target;
      if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked
            ? [...prevData[name], value]
            : prevData[name].filter((item) => item !== value),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  return (
    <div className={'container'}>
      {!isFormSubmitted ? (
        <form id={`breedRescueGrant`}>
          <input
            type="hidden"
            name="freeform_multipage_hash"
            defaultValue={'2ea27d91641a4aa4d727c4026794f541'}
          />
          <input type="hidden" name="params_id" defaultValue={'11172334'} />

          <div class="ff_composer">
            {/* PART 1 */}
            {step === 1 && (
              <StepOne
                formData={formData}
                handleChange={handleChange}
                nextStep={nextStep}
              />
            )}

            {/* PART 2 */}
            {step === 2 && (
              <StepTwo
                formData={formData}
                handleChange={handleChange}
                nextStep={nextStep}
                prevStep={prevStep}
              />
            )}

            {/* PART 3 */}
            {step === 3 && (
              <StepThree
                formData={formData}
                handleChange={handleChange}
                nextStep={nextStep}
                prevStep={prevStep}
              />
            )}

            {/* PART 4 */}
            {step === 4 && (
              <StepFour
                formData={formData}
                handleChange={handleChange}
                prevStep={prevStep}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            )}
          </div>
        </form>
      ) : (
        <div className={'BreedRescueGrant__success active'}>
          <h1>Thank You! Your Response Has Reen Received!</h1>
          {acf.form_success_image && <img src={acf.form_success_image.url} />}
        </div>
      )}
    </div>
  );
};

export default WomensShelterGrant;
