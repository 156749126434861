import React, { useState } from 'react';
import $ from 'jquery';

const StepFour = ({ formData, handleChange, prevStep, setIsFormSubmitted }) => {
  const [errors, setErrors] = useState({});

  const validateStepFour = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.is_the_organization_party_to_any_claim_or_lawsuit) {
      tempErrors['is_the_organization_party_to_any_claim_or_lawsuit'] =
        'Please select an option';
      isValid = false;
    }

    if (
      formData.is_the_organization_party_to_any_claim_or_lawsuit === 'yes' &&
      formData.if_yes_please_explain_2.trim() === ''
    ) {
      tempErrors['if_yes_please_explain_2'] = 'Please explain';
      isValid = false;
    }

    if (
      !formData.are_there_any_business_or_judgment_liens_against_the_organization
    ) {
      tempErrors[
        'are_there_any_business_or_judgment_liens_against_the_organization'
      ] = 'Please select an option';
      isValid = false;
    }

    if (
      formData.are_there_any_business_or_judgment_liens_against_the_organization ===
        'yes' &&
      formData.if_yes_please_explain_again.trim() === ''
    ) {
      tempErrors['if_yes_please_explain_again'] = 'Please explain';
      isValid = false;
    }

    if (!formData.does_the_organization_have_any_debts_outstanding) {
      tempErrors['does_the_organization_have_any_debts_outstanding'] =
        'Please select an option';
      isValid = false;
    }

    if (
      formData.does_the_organization_have_any_debts_outstanding === 'yes' &&
      formData.if_yes_please_explain_5.trim() === ''
    ) {
      tempErrors['if_yes_please_explain_5'] = 'Please explain';
      isValid = false;
    }

    if (
      !formData.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements
    ) {
      tempErrors[
        'is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements'
      ] = 'Please select an option';
      isValid = false;
    }

    if (formData.name_of_organization.trim() === '') {
      tempErrors['name_of_organization'] = 'Please enter name of organization';
      isValid = false;
    }

    if (formData.date_submitted.trim() === '') {
      tempErrors['date_submitted'] = 'Please enter date submitted';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStepFour()) {
      const formDataToSend = new FormData();

      for (const key in formData) {
        if (Array.isArray(formData[key])) {
          if (key === 'how_do_you_house_pets_in_your_shelter') {
            formDataToSend.append(key, formData[key].join(', '));
          } else {
            formData[key].forEach((item) =>
              formDataToSend.append(`${key}[]`, item)
            );
          }
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }

      formDataToSend.append('action', 'handle_womens_shelter_form_submit_ajax');

      $.ajax({
        type: 'POST',
        url: '/wp-admin/admin-ajax.php',
        dataType: 'json',
        data: formDataToSend,
        cache: false,
        processData: false,
        contentType: false,
        success: function (response) {
          setIsFormSubmitted(true);
        },
        error: function (error) {
          console.log(error);
        },
      });
    } else {
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section4">
      <h1>Women’s Shelters Grant — Step 4 </h1>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_is_the_organization_party_to_any_claim_or_lawsuit"
          >
            Is the organization party to any claim or lawsuit?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="is_the_organization_party_to_any_claim_or_lawsuit"
                id="freeform_is_the_organization_party_to_any_claim_or_lawsuit_1"
                value="yes"
                onChange={handleChange}
                checked={
                  formData.is_the_organization_party_to_any_claim_or_lawsuit ===
                  'yes'
                }
              />
              <label htmlFor="freeform_is_the_organization_party_to_any_claim_or_lawsuit_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="is_the_organization_party_to_any_claim_or_lawsuit"
                id="freeform_is_the_organization_party_to_any_claim_or_lawsuit_2"
                value="no"
                onChange={handleChange}
                checked={
                  formData.is_the_organization_party_to_any_claim_or_lawsuit ===
                  'no'
                }
              />
              <label htmlFor="freeform_is_the_organization_party_to_any_claim_or_lawsuit_2">
                No
              </label>
            </li>
          </ul>
          {errors.is_the_organization_party_to_any_claim_or_lawsuit && (
            <div className="error">
              {errors.is_the_organization_party_to_any_claim_or_lawsuit}
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_please_explain"
          >
            If yes, please explain
          </label>

          <textarea
            name="if_yes_please_explain_2"
            cols="50"
            rows="4"
            id="freeform_if_yes_please_explain"
            onChange={handleChange}
            value={formData.if_yes_please_explain_2}
          ></textarea>
          {errors.if_yes_please_explain_2 && (
            <div className="error">{errors.if_yes_please_explain_2}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_are_there_any_business_or_judgment_liens_against_the_organization"
          >
            Are there any business or judgment liens against the organization?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="are_there_any_business_or_judgment_liens_against_the_organization"
                id="freeform_are_there_any_business_or_judgment_liens_against_the_organization_1"
                value="yes"
                onChange={handleChange}
                checked={
                  formData.are_there_any_business_or_judgment_liens_against_the_organization ===
                  'yes'
                }
              />
              <label htmlFor="freeform_are_there_any_business_or_judgment_liens_against_the_organization_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="are_there_any_business_or_judgment_liens_against_the_organization"
                id="freeform_are_there_any_business_or_judgment_liens_against_the_organization_2"
                value="no"
                onChange={handleChange}
                checked={
                  formData.are_there_any_business_or_judgment_liens_against_the_organization ===
                  'no'
                }
              />
              <label htmlFor="freeform_are_there_any_business_or_judgment_liens_against_the_organization_2">
                No
              </label>
            </li>
          </ul>
          {errors.are_there_any_business_or_judgment_liens_against_the_organization && (
            <div className="error">
              {
                errors.are_there_any_business_or_judgment_liens_against_the_organization
              }
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_please_explain_again"
          >
            If yes, please explain
          </label>

          <textarea
            name="if_yes_please_explain_again"
            cols="50"
            rows="4"
            id="freeform_if_yes_please_explain_again"
            onChange={handleChange}
            value={formData.if_yes_please_explain_again}
          ></textarea>
          {errors.if_yes_please_explain_again && (
            <div className="error">{errors.if_yes_please_explain_again}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_does_the_organization_have_any_debts_outstanding"
          >
            Does the organization have any debts outstanding?
          </label>
          <ul>
            <li>
              <input
                type="radio"
                name="does_the_organization_have_any_debts_outstanding"
                id="freeform_does_the_organization_have_any_debts_outstanding_1"
                value="yes"
                onChange={handleChange}
                checked={
                  formData.does_the_organization_have_any_debts_outstanding ===
                  'yes'
                }
              />
              <label htmlFor="freeform_does_the_organization_have_any_debts_outstanding_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="does_the_organization_have_any_debts_outstanding"
                id="freeform_does_the_organization_have_any_debts_outstanding_2"
                value="no"
                onChange={handleChange}
                checked={
                  formData.does_the_organization_have_any_debts_outstanding ===
                  'no'
                }
              />
              <label htmlFor="freeform_does_the_organization_have_any_debts_outstanding_2">
                No
              </label>
            </li>
          </ul>
          {errors.does_the_organization_have_any_debts_outstanding && (
            <div className="error">
              {errors.does_the_organization_have_any_debts_outstanding}
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_please_explain_5"
          >
            If yes, please explain
          </label>

          <textarea
            name="if_yes_please_explain_5"
            cols="50"
            rows="4"
            id="freeform_if_yes_please_explain_5"
            onChange={handleChange}
            checked={formData.if_yes_please_explain_5}
          ></textarea>
          {errors.if_yes_please_explain_5 && (
            <div className="error">{errors.if_yes_please_explain_5}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements"
          >
            Is the organization an endorser or guarantor for any debts not
            listed on this Grant Application or the attached financial
            statements?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements"
                id="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_1"
                value="yes"
                onChange={handleChange}
                checked={
                  formData.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements ===
                  'yes'
                }
              />
              <label htmlFor="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements"
                id="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_2"
                value="no"
                onChange={handleChange}
                checked={
                  formData.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements ===
                  'no'
                }
              />
              <label htmlFor="freeform_is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements_2">
                No
              </label>
            </li>
          </ul>
          {errors.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements && (
            <div className="error">
              {
                errors.is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_please_attach_the_recent_financial_statements_for_your_organization"
          >
            Please attach the recent financial statements for your organization.
          </label>
          <p></p>
          <ul id="upload94"></ul>
          <p></p>
          <input
            type="file"
            name="please_attach_the_recent_financial_statements_for_your_organization_1"
            id="freeform_please_attach_the_recent_financial_statements_for_your_organization0"
            onChange={handleChange}
            multiple
          />

          <input
            type="file"
            name="please_attach_the_recent_financial_statements_for_your_organization_2"
            id="freeform_please_attach_the_recent_financial_statements_for_your_organization1"
            onChange={handleChange}
            multiple
          />

          <label
            className="field_label"
            htmlFor="freeform_please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization"
          >
            Please attach the IRS Determination Letter confirming Section 501
            (c)(3) status of your organization.
          </label>

          <p></p>
          <ul id="upload95"></ul>
          <p></p>
          <input
            type="file"
            name="please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_1"
            id="freeform_please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization0"
            onChange={handleChange}
            multiple
          />

          <input
            type="file"
            name="please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_2"
            id="freeform_please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization1"
            onChange={handleChange}
            multiple
          />

          <label
            className="field_label"
            htmlFor="freeform_name_of_organization"
          >
            Name of Organization
          </label>

          <input
            type="text"
            name="name_of_organization"
            id="freeform_name_of_organization"
            maxLength="150"
            onChange={handleChange}
            value={formData.name_of_organization}
          />
          {errors.name_of_organization && (
            <div className="error">{errors.name_of_organization}</div>
          )}

          <label className="field_label" htmlFor="freeform_date_submitted">
            Date Submitted
          </label>

          <input
            type="text"
            name="date_submitted"
            id="freeform_date_submitted"
            maxLength="150"
            onChange={handleChange}
            value={formData.date_submitted}
          />
          {errors.date_submitted && (
            <div className="error">{errors.date_submitted}</div>
          )}
          <label className="field_label" htmlFor="freeform_"></label>

          <p>By clicking on the “I agree and certify” button, below, I:</p>
          <ul>
            (1) represent that I am duly authorized to execute and deliver this
            Grant Application on behalf of the organization;
          </ul>
          <ul>(2) certify to the truth of my statements above.</ul>
          <p></p>
          <p>
            Further, by clicking on the “I agree and certify” button, below, I
            agree and certify that I am authenticating the information provided
            as I would be with a written signature. Any misrepresentation on
            this application may result in the revocation of the grant.
          </p>
          <p></p>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_"></label>
          <a type="submit" className="btn bg-black" onClick={prevStep} id="4">
            Back
          </a>
          <input
            type="hidden"
            name="email_subject"
            value="New application - Women’s Shelters Grant"
          />
          <input
            type="hidden"
            name="email_applicant"
            value="Women’s Shelters Grant"
          />
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_"></label>
          <input
            type="submit"
            name="submit"
            onClick={handleSubmit}
            defaultValue={'I agree and certify'}
            className="btn pull-right"
          />
        </div>
      </div>
    </span>
  );
};

export default StepFour;
