import React, { Component, useEffect } from 'react'
import { Link } from 'react-router-dom';
import hamburger from '../../../src/assets/img/hamburger.png'

export class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
        this.activate = this.activate.bind(this)

    }

   state = {
       header: [],
       isLoaded: false,
       acf: []
   }

   componentDidMount () {
    this.props.fetchPosts("header")
   }

   componentDidUpdate (prevProps) {
       if (prevProps.header !== this.props.header) { 
           this.setState({ 
               header: this.props.header[0],
               acf: this.props.header[0].acf,
               isLoaded: true,
               toggle: false,
            }) 
        }
   }

   toggle(){ // replace with hook later
    this.setState({toggle: !this.state.toggle})
   }

   activate(){
    this.setState({
        acf: this.state.acf.content[0],
        toggle: false
    })
   }

   render () {
    let newHeader = undefined
    if (this.state.header && this.state.header.acf && this.state.header.acf.content && this.state.header.acf.content[0]) newHeader= this.state.header.acf.content[0]

    return (
        <header className="header">
            <div className="header__wrap">

            {newHeader && (
                <Link  to={`/`} onClick={this.activate}>
                    <img src={newHeader.humane_menu_logo.url} />
                </Link>
            )}

            {newHeader && (
                <a className="header__button btnn" href={newHeader.link.url}>{newHeader.link.title}</a>
            )}
            </div>

            <div className="header__container">
                <img className="header__hamburger" src={hamburger} onClick={this.toggle}/>
                <div className={`header__nav-wrap ${this.state.toggle ? 'open' : ''}`}>
                    <ul className="header__nav-bar">
                        {newHeader && (
                            newHeader.humane_menu_section.map((headerItem, index)=>{
                                let titleLink = headerItem.humane_menu_title
                                if (titleLink.split(' ').length > 1 ) titleLink = titleLink.split(' ').join('-')
                                    // /About => about === about? or: /Ace-Awards => ace awards === ace awards ?
                                    let active = window.location.pathname.slice(1).split("-").join(" ").toLowerCase() === headerItem.humane_menu_title.toLowerCase()
                                    
                                    return (
                                        <Link to={`/${titleLink}`} onClick={this.activate}>
                                            <li className={`nav-${index} ${active ? 'active' : ''}`} dangerouslySetInnerHTML={{__html: headerItem.humane_menu_title}}/>   
                                        </Link>
                                    )
                             })
                        )}
                    </ul>
                </div>
            </div>
        </header>
        )
    }   
}
export default Header
