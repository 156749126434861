import React, { useState } from 'react';

const StepTwo = ({ formData, handleChange, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});

  const validateStepTwo = () => {
    let tempErrors = {};
    let isValid = true;

    if (
      formData.what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document.trim() ===
      ''
    ) {
      isValid = false;
      tempErrors[
        'what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document'
      ] = 'this field is required';
    }

    if (
      formData.what_is_the_overall_goal_and_mission_of_your_organization.trim() ===
      ''
    ) {
      isValid = false;
      tempErrors['what_is_the_overall_goal_and_mission_of_your_organization'] =
        'this field is required';
    }

    if (
      formData.list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years.trim() ===
      ''
    ) {
      isValid = false;
      tempErrors[
        'list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years'
      ] = 'this field is required';
    }

    if (
      formData.in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only.trim() ===
      ''
    ) {
      isValid = false;
      tempErrors[
        'in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only'
      ] = 'this field is required';
    }

    if (
      formData.how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants.trim() ===
      ''
    ) {
      isValid = false;
      tempErrors[
        'how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants'
      ] = 'this field is required';
    }

    if (
      formData.how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants.trim() ===
      ''
    ) {
      isValid = false;
      tempErrors[
        'how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants'
      ] = 'this field is required';
    }

    if (!formData.do_you_charge_residents_a_fee_for_housing_their_pets) {
      isValid = false;
      tempErrors['do_you_charge_residents_a_fee_for_housing_their_pets'] =
        'please select yes or no';
    }

    if (
      formData.do_you_charge_residents_a_fee_for_housing_their_pets === 'yes' &&
      formData.if_yes_what_is_the_fee_per_pet_for_how_long.trim() === ''
    ) {
      isValid = false;
      tempErrors['if_yes_what_is_the_fee_per_pet_for_how_long'] =
        'this field is required if selected yes';
    }

    if (formData.how_do_you_house_pets_in_your_shelter.length === 0) {
      isValid = false;
      tempErrors['how_do_you_house_pets_in_your_shelter'] =
        'please select atleast one';
    }

    if (
      formData.how_do_you_house_pets_in_your_shelter.includes(
        'Other (please explain)'
      ) &&
      formData.if_other_please_explain.trim() === ''
    ) {
      isValid = false;
      tempErrors['if_other_please_explain'] =
        'this field is required if selected other';
    }

    if (
      !formData.do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing
    ) {
      isValid = false;
      tempErrors[
        'do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing'
      ] = 'please select yes or no';
    }

    if (
      !formData.do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter
    ) {
      isValid = false;
      tempErrors[
        'do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter'
      ] = 'please select yes or no';
    }

    if (
      formData.do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter ===
        'yes' &&
      formData.if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization.trim() ===
        ''
    ) {
      isValid = false;
      tempErrors[
        'if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization'
      ] = 'this field is required if selected yes';
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStepTwo()) {
      nextStep();
    } else {
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section2">
      <h1>Women’s Shelters Grant — Step 2 </h1>
      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_4_a_what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document"
          >
            What is your organization’s purpose statement in its Articles of
            Incorporation or similar governing document?
          </label>

          <textarea
            name="what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document"
            cols="50"
            rows="6"
            id="freeform_4_a_what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document"
            onChange={handleChange}
            value={
              formData.what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document
            }
          ></textarea>

          {errors.what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document && (
            <div className="error">
              {
                errors.what_is_your_organizations_purpose_statement_in_its_articles_of_incorporation_or_similar_governing_document
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_b_what_is_the_overall_goal_and_mission_of_your_organization"
          >
            What is the overall goal and mission of your organization?
          </label>

          <textarea
            name="what_is_the_overall_goal_and_mission_of_your_organization"
            cols="50"
            rows="6"
            id="freeform_b_what_is_the_overall_goal_and_mission_of_your_organization"
            onChange={handleChange}
            value={
              formData.what_is_the_overall_goal_and_mission_of_your_organization
            }
          ></textarea>
          {errors.what_is_the_overall_goal_and_mission_of_your_organization && (
            <div className="error">
              {errors.what_is_the_overall_goal_and_mission_of_your_organization}
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_5_list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years"
          >
            5. List the award amount(s), source(s) and purpose(s) of any grants
            your organization has received from any grantmaking body in the last
            three years.
          </label>

          <textarea
            name="list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years"
            cols="50"
            rows="6"
            id="freeform_5_list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years"
            onChange={handleChange}
            value={
              formData.list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years
            }
          ></textarea>
          {errors.list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years && (
            <div className="error">
              {
                errors.list_the_award_amounts_sources_and_purposes_of_any_grants_your_organization_has_received_from_any_grantmaking_body_in_the_last_three_years
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_6_in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only"
          >
            6. In what way(s) has the ability to house pets at your shelter
            affected residents and your overall mission? (Applicants from
            Women’s Shelters only.)
          </label>

          <textarea
            name="in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only"
            cols="50"
            rows="18"
            id="freeform_6_in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only"
            onChange={handleChange}
            value={
              formData.in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only
            }
          ></textarea>
          {errors.in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only && (
            <div className="error">
              {
                errors.in_what_ways_has_the_ability_to_house_pets_at_your_shelter_affected_residents_and_your_overall_mission_applicants_from_womens_shelters_only
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_7_how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants"
          >
            7. How many and what types of pets do you currently house at your
            shelter now? (All applicants)
          </label>

          <textarea
            name="how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants"
            cols="50"
            rows="6"
            id="freeform_7_how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants"
            onChange={handleChange}
            value={
              formData.how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants
            }
          ></textarea>
          {errors.how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants && (
            <div className="error">
              {
                errors.how_many_and_what_types_of_pets_do_you_currently_house_at_your_shelter_now_all_applicants
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_8_how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants"
          >
            8. How many and what types of pets can you accommodate at your
            shelter overall? (All applicants)
          </label>

          <textarea
            name="how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants"
            cols="50"
            rows="6"
            id="freeform_8_how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants"
            onChange={handleChange}
            value={
              formData.how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants
            }
          ></textarea>
          {errors.how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants && (
            <div className="error">
              {
                errors.how_many_and_what_types_of_pets_can_you_accommodate_at_your_shelter_overall_all_applicants
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_9_do_you_charge_residents_a_fee_for_housing_their_pets"
          >
            9. Do you charge residents a fee for housing their pets?
          </label>
          <ul>
            <li>
              <input
                type="radio"
                name="do_you_charge_residents_a_fee_for_housing_their_pets"
                id="freeform_9_do_you_charge_residents_a_fee_for_housing_their_pets_1"
                value="yes"
                onChange={handleChange}
                checked={
                  formData.do_you_charge_residents_a_fee_for_housing_their_pets ===
                  'yes'
                }
              />
              <label htmlFor="freeform_9_do_you_charge_residents_a_fee_for_housing_their_pets_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="do_you_charge_residents_a_fee_for_housing_their_pets"
                id="freeform_9_do_you_charge_residents_a_fee_for_housing_their_pets_2"
                value="no"
                onChange={handleChange}
                checked={
                  formData.do_you_charge_residents_a_fee_for_housing_their_pets ===
                  'no'
                }
              />
              <label htmlFor="freeform_9_do_you_charge_residents_a_fee_for_housing_their_pets_2">
                No
              </label>
            </li>
          </ul>
          {errors.do_you_charge_residents_a_fee_for_housing_their_pets && (
            <div className="error">
              {errors.do_you_charge_residents_a_fee_for_housing_their_pets}
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_what_is_the_fee_per_pet_for_how_long"
          >
            If yes, what is the fee per pet? ( $_____ for ______ how long)
          </label>

          <input
            type="text"
            name="if_yes_what_is_the_fee_per_pet_for_how_long"
            id="freeform_if_yes_what_is_the_fee_per_pet_for_how_long"
            maxLength="150"
            onChange={handleChange}
            value={formData.if_yes_what_is_the_fee_per_pet_for_how_long}
          />
          {errors.if_yes_what_is_the_fee_per_pet_for_how_long && (
            <div className="error">
              {errors.if_yes_what_is_the_fee_per_pet_for_how_long}
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_10_how_do_you_house_pets_in_your_shelter"
          >
            10. How do you house pets in your shelter?
          </label>

          <ul>
            <li>
              <input
                type="checkbox"
                name="how_do_you_house_pets_in_your_shelter"
                value="In a separate room inside the shelter"
                id="freeform_10_how_do_you_house_pets_in_your_shelter_1"
                onChange={handleChange}
                checked={formData.how_do_you_house_pets_in_your_shelter.includes(
                  'In a separate room inside the shelter'
                )}
              />
              <label htmlFor="freeform_10_how_do_you_house_pets_in_your_shelter_1">
                In a separate room inside the shelter
              </label>
            </li>
            <li>
              <input
                type="checkbox"
                name="how_do_you_house_pets_in_your_shelter"
                value="In residents rooms"
                id="freeform_10_how_do_you_house_pets_in_your_shelter_2"
                onChange={handleChange}
                checked={formData.how_do_you_house_pets_in_your_shelter.includes(
                  'In residents rooms'
                )}
              />
              <label htmlFor="freeform_10_how_do_you_house_pets_in_your_shelter_2">
                In residents’ rooms
              </label>
            </li>
            <li>
              <input
                type="checkbox"
                name="how_do_you_house_pets_in_your_shelter"
                value="In a separate structure on the premises"
                id="freeform_10_how_do_you_house_pets_in_your_shelter_3"
                onChange={handleChange}
                checked={formData.how_do_you_house_pets_in_your_shelter.includes(
                  'In a separate structure on the premises'
                )}
              />
              <label htmlFor="freeform_10_how_do_you_house_pets_in_your_shelter_3">
                In a separate structure on the premises
              </label>
            </li>
            <li>
              <input
                type="checkbox"
                name="how_do_you_house_pets_in_your_shelter"
                value="Other (please explain)"
                id="freeform_10_how_do_you_house_pets_in_your_shelter_4"
                onChange={handleChange}
                checked={formData.how_do_you_house_pets_in_your_shelter.includes(
                  'Other (please explain)'
                )}
              />
              <label htmlFor="freeform_10_how_do_you_house_pets_in_your_shelter_4">
                Other (please explain)
              </label>
            </li>
          </ul>
          {errors.how_do_you_house_pets_in_your_shelter && (
            <div className="error">
              {errors.how_do_you_house_pets_in_your_shelter}
            </div>
          )}

          <div className="unit size1of2">
            <label
              className="field_label"
              htmlFor="freeform_if_other_please_explain"
            >
              If other, please explain.
            </label>
            <textarea
              name="if_other_please_explain"
              cols="50"
              rows="3"
              id="freeform_if_other_please_explain"
              onChange={handleChange}
              value={formData.if_other_please_explain}
            ></textarea>
            {errors.if_other_please_explain && (
              <div className="error">{errors.if_other_please_explain}</div>
            )}
          </div>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_11_do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing"
          >
            11. Do you help residents identify and transition to pet-friendly
            permanent housing?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing"
                id="freeform_11_do_you_help_residents_identify_and_transition_to_pet-friendly_permanent_housing_1"
                onChange={handleChange}
                checked={
                  formData.do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing ===
                  'yes'
                }
                value="yes"
              />
              <label htmlFor="freeform_11_do_you_help_residents_identify_and_transition_to_pet-friendly_permanent_housing_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing"
                id="freeform_11_do_you_help_residents_identify_and_transition_to_pet-friendly_permanent_housing_2"
                onChange={handleChange}
                checked={
                  formData.do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing ===
                  'no'
                }
                value="no"
              />
              <label htmlFor="freeform_11_do_you_help_residents_identify_and_transition_to_pet-friendly_permanent_housing_2">
                No
              </label>
            </li>
          </ul>
          {errors.do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing && (
            <div className="error">
              {
                errors.do_you_help_residents_identify_and_transition_to_pet_friendly_permanent_housing
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_12_do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter"
          >
            12. Do you have a relationship with a local AKC rescue group or
            animal shelter?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter"
                id="freeform_12_do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter_1"
                onChange={handleChange}
                checked={
                  formData.do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter ===
                  'yes'
                }
                value="yes"
              />
              <label htmlFor="freeform_12_do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter"
                id="freeform_12_do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter_2"
                onChange={handleChange}
                checked={
                  formData.do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter ===
                  'no'
                }
                value="no"
              />
              <label htmlFor="freeform_12_do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter_2">
                No
              </label>
            </li>
          </ul>
          {errors.do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter && (
            <div className="error">
              {
                errors.do_you_have_a_relationship_with_a_local_akc_rescue_group_or_animal_shelter
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization"
          >
            If Yes, please describe and indicate the name and address of the
            organization.
          </label>

          <textarea
            name="if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization"
            cols="50"
            rows="4"
            id="freeform_if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization"
            onChange={handleChange}
            value={
              formData.if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization
            }
          ></textarea>
          {errors.if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization && (
            <div className="error">
              {
                errors.if_yes_please_describe_and_indicate_the_name_and_address_of_the_organization
              }
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>

          <a href className="btn bg-black" onClick={prevStep} id="2">
            Back
          </a>
        </div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>
          <a href className="btn pull-right" id="2" onClick={handleNext}>
            Continue To Part 3
          </a>
        </div>
      </div>
    </span>
  );
};

export default StepTwo;
