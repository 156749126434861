import React from 'react';
// import '../../assets/stylesheets/test.scss'

class FlexibleContent extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        let container = this.props.acf.description_container_option

        return (    
            <div className={`flexibleContent ${container === '1' ? 'container' : '' }`}>
                <div dangerouslySetInnerHTML={{__html: this.props.acf.text}} />
            </div>
        )
    }
}

export default FlexibleContent