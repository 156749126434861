import React, { useState } from 'react';

const StepOne = ({ formData, handleChange, nextStep }) => {
  const [errors, setErrors] = useState({});

  const validateStepOne = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.name_of_organization.trim() === '') {
      isValid = false;
      tempErrors['name_of_organization'] = 'Name of Organization is required';
    }

    if (formData.app_legal_structure.trim() === '') {
      isValid = false;
      tempErrors['app_legal_structure'] = 'Legal Structure is required';
    }

    if (formData.rescue_ein.trim() === '') {
      isValid = false;
      tempErrors['rescue_ein'] = 'Employee Identification Number is required';
    }

    if (formData.app_tax_status.trim() === '') {
      isValid = false;
      tempErrors['app_tax_status'] = 'Plesae select Tax Status';
    }

    if (formData.app_contact_name.trim() === '') {
      isValid = false;
      tempErrors['app_contact_name'] = 'Contact Name is required';
    }

    if (formData.app_contact_title.trim() === '') {
      isValid = false;
      tempErrors['app_contact_title'] = 'Contact Title is required';
    }

    if (formData.app_contact_email.trim() === '') {
      isValid = false;
      tempErrors['app_contact_email'] = 'Contact Email is required';
    }

    if (formData.app_street_address.trim() === '') {
      isValid = false;
      tempErrors['app_street_address'] = 'Street Address is required';
    }

    if (formData.app_city.trim() === '') {
      isValid = false;
      tempErrors['app_city'] = 'City is required';
    }

    if (formData.app_state.trim() === '') {
      isValid = false;
      tempErrors['app_state'] = 'State is required';
    }

    if (formData.app_zip.trim() === '') {
      isValid = false;
      tempErrors['app_zip'] = 'Postal Code is required';
    }

    if (formData.app_phone.trim() === '') {
      isValid = false;
      tempErrors['app_phone'] = 'Phone Number is required';
    }

    if (formData.app_breeds.trim() === '') {
      isValid = false;
      tempErrors['app_breeds'] =
        'Breed(s) Served By Your Organization is required';
    }

    if (formData.app_website.trim() === '') {
      isValid = false;
      tempErrors['app_website'] = 'Website is required';
    }

    if (formData.app_geography.trim() === '') {
      isValid = false;
      tempErrors['app_geography'] = 'Geographic Area Served is required';
    }

    if (formData.app_year_founded.trim() === '') {
      isValid = false;
      tempErrors['app_year_founded'] = 'Year Founded is required';
    }

    if (formData.app_employees.trim() === '') {
      isValid = false;
      tempErrors['app_employees'] = 'Number of Employees is required';
    }

    if (formData.app_volunteers.trim() === '') {
      isValid = false;
      tempErrors['app_volunteers'] = 'Number of Volunteers is required';
    }

    if (formData.app_fiscal_year.trim() === '') {
      isValid = false;
      tempErrors['app_fiscal_year'] = 'Most recent fiscal year end is required';
    }

    if (!formData.app_apply_previously) {
      isValid = false;
      tempErrors['app_apply_previously'] = 'Please select yes or no';
    }

    if (
      formData.app_apply_previously === 'yes' &&
      formData.app_apply_year.trim() === ''
    ) {
      isValid = false;
      tempErrors['app_apply_year'] = "Year is required when selected 'Yes'";
    }

    if (!formData.app_previous_grant) {
      isValid = false;
      tempErrors['app_previous_grant'] = 'Please select yes or no';
    }

    if (
      formData.app_previous_grant === 'yes' &&
      formData.if_yes_what_year_did_you_submit_an_application.trim() === ''
    ) {
      isValid = false;
      tempErrors['if_yes_what_year_did_you_submit_an_application'] =
        "Year is required when selected 'Yes'";
    }

    if (formData.organizations_purpose_statement.trim() === '') {
      isValid = false;
      tempErrors['organizations_purpose_statement'] =
        'Purpose Statement is required';
    }

    if (formData.overall_goal_and_mission_of_your_organization.trim() === '') {
      isValid = false;
      tempErrors['overall_goal_and_mission_of_your_organization'] =
        'Goal and Mission is required';
    }

    if (formData.app_awards.trim() === '') {
      isValid = false;
      tempErrors['app_awards'] = 'Awards is required';
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStepOne()) {
      nextStep();
    } else {
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section1">
      <h1>Breed Rescue Grant Application </h1>
      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>
          Breed Rescue Grant Application
          <label className="field_label" htmlFor="freeform_"></label>
          The AKC Humane Fund is dedicated to assisting groups that protect
          pets, including those involved in pet welfare, rescue and health, such
          as the AKC Canine Health Foundation and AKC Rescue Network. The rescue
          grant program provides financial assistance to not-for-profit canine
          rescue groups that are taking on significant, rescue-related expenses.
          Grants are used to help cover the costs of veterinary care, spaying
          and neutering, vital supplies and more.
          <label className="field_label" htmlFor="freeform_"></label>
          <h3>Qualifications &amp; Eligibility </h3>
          <p>
            Applicant must provide rescue or rehabilitation services solely for
            dogs listed on the American Kennel Club’s (“AKC”) then-current list
            of AKC-registrable breeds (the “Complete Breed List”). A copy of the
            current Complete Breed List can be found on the AKC’s website at
            www.akc.org.
          </p>
          <li>
            Applicant must be: (1) an AKC Parent Club that conducts breed rescue
            activities or (2) an organization that is exempt from Federal income
            taxation under Section 501(c)(3) of the Internal Revenue Code of
            1986, as amended, that conducts breed rescue activities and is
            recommended in writing by an AKC Parent Club;
          </li>
          <li>
            Grant Applications must be submitted by Parent Clubs with a cover
            letter on Parent Club letterhead, or by non-Parent Club, 501c3
            organizations with a letter of recommendation from a Parent Club on
            Parent Club letterhead.
          </li>
          <li>
            AKC Humane Fund Rescue Grants are awarded for specific projects and
            essential operational support. The AKC Humane Fund Rescue Grants may
            not be used for fundraising purposes or capital improvements.
          </li>
          <li>
            An applicant may not apply for an AKC Humane Fund Rescue Grant if it
            has been awarded a grant from the AKC Humane Fund twice in the five
            calendar year period immediately preceding the application deadline.
          </li>
          <li>
            Applicants that are awarded grants must comply with a reporting
            process as indicated by the AKC Humane Fund.
          </li>
          <h3>Application Instructions </h3>
          Submitted applications must include the following materials, in this
          order:
          <li>
            Completed Grant Application form with additional sheets attached as
            needed to answer questions. The original form is required and must
            be signed and dated.
          </li>
          <li>
            Financial information regarding your organization, including the
            most recent financial statements (preferably audited), total current
            budget for the last completed fiscal year and for the current fiscal
            year of the organization, and a list detailing the principal sources
            of ongoing annual support for the organization.
          </li>
          <li>
            Copy of the IRS Determination Letter confirming Section 501 (c)(3)
            status (if applicable).
          </li>
          <p>
            If you would prefer to fill out a paper version of this application,
            please see the PDF
            <a href="https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2019/09/18151434/Breed-Rescue-Grant-App-2019.pdf">
              here.
            </a>
          </p>
          <label className="field_label" htmlFor="freeform_"></label>
          <h3>Application Information</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_name_of_organization"
          >
            Name of Organization
          </label>

          <input
            type="text"
            name="name_of_organization"
            id="freeform_name_of_organization"
            maxLength="150"
            onChange={handleChange}
            value={formData.name_of_organization}
          />
          {errors.name_of_organization && (
            <div className="error">{errors.name_of_organization}</div>
          )}
        </div>

        <div className="unit size1of2"></div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_legal_structure">
            Legal Structure
          </label>

          <input
            type="text"
            name="app_legal_structure"
            id="freeform_app_legal_structure"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_legal_structure}
          />
          {errors.app_legal_structure && (
            <div className="error">{errors.app_legal_structure}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_rescue_ein">
            Employee Identification Number
          </label>

          <input
            type="text"
            name="rescue_ein"
            id="freeform_rescue_ein"
            maxLength="150"
            onChange={handleChange}
            value={formData.rescue_ein}
          />
          {errors.rescue_ein && (
            <div className="error">{errors.rescue_ein}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_tax_status">
            Tax Status - Does your organization have Section 501(c)(3) of the
            code status?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="app_tax_status"
                id="freeform_app_tax_status_1"
                checked={formData.app_tax_status === 'yes'}
                onChange={handleChange}
                value="yes"
              />
              <label htmlFor="freeform_app_tax_status_1">Yes</label>
            </li>
            <li>
              <input
                type="radio"
                name="app_tax_status"
                id="freeform_app_tax_status_2"
                checked={formData.app_tax_status === 'no'}
                onChange={handleChange}
                value="no"
              />
              <label htmlFor="freeform_app_tax_status_2">No</label>
            </li>
          </ul>
          {errors.app_tax_status && (
            <div className="error">{errors.app_tax_status}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_contact_name">
            Contact Name
          </label>

          <input
            type="text"
            name="app_contact_name"
            id="freeform_app_contact_name"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_contact_name}
          />
          {errors.app_contact_name && (
            <div className="error">{errors.app_contact_name}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_contact_title">
            Contact Title
          </label>

          <input
            type="text"
            name="app_contact_title"
            id="freeform_app_contact_title"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_contact_title}
          />
          {errors.app_contact_title && (
            <div className="error">{errors.app_contact_title}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_contact_email">
            Contact Email Address
          </label>

          <input
            type="email"
            name="app_contact_email"
            id="freeform_app_contact_email"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_contact_email}
          />
          {errors.app_contact_email && (
            <div className="error">{errors.app_contact_email}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h3>Organization Contact Information</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h4>Mailing Address</h4>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_street_address">
            Street Address
          </label>

          <input
            type="text"
            name="app_street_address"
            id="freeform_app_street_address"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_street_address}
          />
          {errors.app_street_address && (
            <div className="error">{errors.app_street_address}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_city">
            City
          </label>

          <input
            type="text"
            name="app_city"
            id="freeform_app_city"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_city}
          />
          {errors.app_city && <div className="error">{errors.app_city}</div>}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_state">
            State
          </label>

          <input
            type="text"
            name="app_state"
            defaultValue=""
            id="freeform_app_state"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_state}
          />
          {errors.app_state && <div className="error">{errors.app_state}</div>}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_zip">
            Postal Code
          </label>

          <input
            type="text"
            name="app_zip"
            id="freeform_app_zip"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_zip}
          />
          {errors.app_zip && <div className="error">{errors.app_zip}</div>}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_phone">
            Phone Number
          </label>

          <input
            type="text"
            name="app_phone"
            defaultValue=""
            id="freeform_app_phone"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_phone}
          />
        </div>
        {errors.app_phone && <div className="error">{errors.app_phone}</div>}
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h3>Organization Information</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_breeds">
            Breed(s) Served By Your Organization
          </label>

          <input
            type="text"
            name="app_breeds"
            id="freeform_app_breeds"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_breeds}
          />
          {errors.app_breeds && (
            <div className="error">{errors.app_breeds}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_website">
            Website
          </label>

          <input
            type="text"
            name="app_website"
            id="freeform_app_website"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_website}
          />
          {errors.app_website && (
            <div className="error">{errors.app_website}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_geography">
            Geographic Area Served
          </label>

          <input
            type="text"
            name="app_geography"
            id="freeform_app_geography"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_geography}
          />
          {errors.app_geography && (
            <div className="error">{errors.app_geography}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_year_founded">
            Year Founded
          </label>

          <input
            type="text"
            name="app_year_founded"
            id="freeform_app_year_founded"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_year_founded}
          />
          {errors.app_year_founded && (
            <div className="error">{errors.app_year_founded}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_employees">
            Number of Employees
          </label>

          <input
            type="text"
            name="app_employees"
            id="freeform_app_employees"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_employees}
          />
          {errors.app_employees && (
            <div className="error">{errors.app_employees}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_volunteers">
            Number of Volunteers
          </label>

          <input
            type="text"
            name="app_volunteers"
            id="freeform_app_volunteers"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_volunteers}
          />
          {errors.app_volunteers && (
            <div className="error">{errors.app_volunteers}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_fiscal_year">
            Most recent fiscal year end (DD/MM)
          </label>

          <input
            type="text"
            name="app_fiscal_year"
            id="freeform_app_fiscal_year"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_fiscal_year}
          />
          {errors.app_fiscal_year && (
            <div className="error">{errors.app_fiscal_year}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_app_apply_previously"
          >
            Have you applied for an AKC Humane Fund grant previously?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="app_apply_previously"
                id="freeform_app_apply_previously_1"
                value="yes"
                onChange={handleChange}
                checked={formData.app_apply_previously === 'yes'}
              />
              <label htmlFor="freeform_app_apply_previously_1">Yes</label>
            </li>
            <li>
              <input
                type="radio"
                name="app_apply_previously"
                id="freeform_app_apply_previously_2"
                value="no"
                onChange={handleChange}
                checked={formData.app_apply_previously === 'no'}
              />
              <label htmlFor="freeform_app_apply_previously_2">No</label>
            </li>
          </ul>
          {errors.app_apply_previously && (
            <div className="error">{errors.app_apply_previously}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_apply_year">
            If yes, in what year did you apply? (YYYY)
          </label>

          <input
            type="text"
            name="app_apply_year"
            id="freeform_app_apply_year"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_apply_year}
          />
          {errors.app_apply_year && (
            <div className="error">{errors.app_apply_year}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_previous_grant">
            Have you ever been awarded a grant from the AKC Humane Fund
            previously?
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="app_previous_grant"
                id="freeform_app_previous_grant_1"
                value="yes"
                onChange={handleChange}
                checked={formData.app_previous_grant === 'yes'}
              />
              <label htmlFor="freeform_app_previous_grant_1">Yes</label>
            </li>
            <li>
              <input
                type="radio"
                name="app_previous_grant"
                id="freeform_app_previous_grant_2"
                value="no"
                onChange={handleChange}
                checked={formData.app_previous_grant === 'no'}
              />
              <label htmlFor="freeform_app_previous_grant_2">No</label>
            </li>
          </ul>
          {errors.app_previous_grant && (
            <div className="error">{errors.app_previous_grant}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_what_year_did_you_submit_an_application"
          >
            If yes, what year did you receive the award? (YYYY)
          </label>

          <input
            type="text"
            name="if_yes_what_year_did_you_submit_an_application"
            id="freeform_if_yes_what_year_did_you_submit_an_application"
            maxLength="150"
            onChange={handleChange}
            value={formData.if_yes_what_year_did_you_submit_an_application}
          />
          {errors.if_yes_what_year_did_you_submit_an_application && (
            <div className="error">
              {errors.if_yes_what_year_did_you_submit_an_application}
            </div>
          )}
          <label
            className="field_label"
            htmlFor="organizations_purpose_statement"
          >
            What is your organization’s purpose statement in its Articles of
            Incorporation or similar governing document?
          </label>

          <textarea
            name="organizations_purpose_statement"
            cols="50"
            rows="6"
            id="organizations_purpose_statement"
            onChange={handleChange}
            value={formData.organizations_purpose_statement}
          ></textarea>
          {errors.organizations_purpose_statement && (
            <div className="error">
              {errors.organizations_purpose_statement}
            </div>
          )}
          <label
            className="field_label"
            htmlFor="overall_goal_and_mission_of_your_organization"
          >
            What is the overall goal and mission of your organization?
          </label>

          <textarea
            name="overall_goal_and_mission_of_your_organization"
            cols="50"
            rows="6"
            id="overall_goal_and_mission_of_your_organization"
            onChange={handleChange}
            value={formData.overall_goal_and_mission_of_your_organization}
          ></textarea>
          {errors.overall_goal_and_mission_of_your_organization && (
            <div>{errors.overall_goal_and_mission_of_your_organization}</div>
          )}

          <label className="field_label" htmlFor="freeform_app_awards">
            List the award amount(s), source(s), and purpose(s) of any grants
            your organization has received from any grantmaking body in the last
            three years:
          </label>

          <textarea
            name="app_awards"
            cols="50"
            rows="6"
            id="freeform_app_awards"
            onChange={handleChange}
            value={formData.app_awards}
          ></textarea>
          {errors.app_awards && (
            <div className="error">{errors.app_awards}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>

          <button href className="btn pull-right" id="1" onClick={handleNext}>
            Continue To Part 2
          </button>
        </div>
      </div>
    </span>
  );
};

export default StepOne;
