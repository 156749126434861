import './App.scss';
import { Provider } from 'react-redux'
import store from './store/index'
import HumaneFund from './components/HumaneFund'
import { BrowserRouter } from 'react-router-dom';


function App() {
  console.log("start appp")
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <HumaneFund />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
