import React, { useState, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const BreedRescueGrant = ({ acf }) => {
  const [step, setStep] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    //step one
    name_of_organization: '',
    app_legal_structure: '',
    rescue_ein: '',
    app_tax_status: '',
    app_contact_name: '',
    app_contact_title: '',
    app_contact_email: '',
    app_street_address: '',
    app_city: '',
    app_state: '',
    app_zip: '',
    app_phone: '',
    app_breeds: '',
    app_website: '',
    app_geography: '',
    app_year_founded: '',
    app_employees: '',
    app_volunteers: '',
    app_fiscal_year: '',
    app_apply_previously: '',
    app_apply_year: '',
    app_previous_grant: '',
    if_yes_what_year_did_you_submit_an_application: '',
    organizations_purpose_statement: '',
    overall_goal_and_mission_of_your_organization: '',
    app_awards: '',
    //step two
    app_grant_type: [],
    amount_of_funds_requested_up_to_5000_maximum: '',
    for_each_of: '1 year',
    app_special_project: '',
    app_anticipated_start_date: '',
    app_anticipated_end_date: '',
    // step three
    app_total_budget: '',
    app_funding_sources: '',
    app_timeline: '',
    app_outcomes: '',
    app_capacity: '',
    app_evaluate: '',
    app_breed_rescue: '',
    number_rescued: '',
    app_grant_use: '',
    app_grant_why: '',
    please_attach_the_recent_financial_statements_for_your_organization_1: [],
    please_attach_the_recent_financial_statements_for_your_organization_2: [],
    please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_1: [],
    please_attach_the_irs_determination_letter_confirming_section_501_c3_status_of_your_organization_2: [],
    letters_of_reference_1: [],
    letters_of_reference_2: [],
    app_ref_name1: '',
    app_ref_address1: '',
    app_ref_city1: '',
    app_ref_state1: '',
    app_ref_zip1: '',
    app_ref_phone1: '',
    app_ref_name2: '',
    app_ref_address2: '',
    app_ref_city2: '',
    app_ref_state2: '',
    app_ref_zip2: '',
    app_ref_phone2: '',
    app_ref_name3: '',
    app_ref_address3: '',
    app_ref_city3: '',
    app_ref_state3: '',
    app_ref_zip3: '',
    app_ref_phone3: '',
    is_the_organization_party_to_any_claim_or_lawsuit: '',
    are_there_any_business_or_judgment_liens_against_the_organization: '',
    does_the_organization_have_any_debts_outstanding: '',
    is_the_organization_an_endorser_or_guarantor_for_any_debts_not_listed_on_this_grant_application_or_the_attached_financial_statements:
      '',
    email_subject: 'New Application - Breed Rescue Grant',
    email_applicant: 'Breed Rescue Grant',
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, files } = e.target;

    if (files) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: Array.from(files),
      }));
    } else {
      const { value, type, checked } = e.target;
      if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked
            ? [...prevData[name], value]
            : prevData[name].filter((item) => item !== value),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  return (
    <div className="container">
      {!isFormSubmitted ? (
        <form id={`breedRescueGrant`}>
          <input
            type="hidden"
            name="freeform_multipage_hash"
            defaultValue="2ea27d91641a4aa4d727c4026794f541"
          />
          <input type="hidden" name="params_id" defaultValue="11172334" />

          <div class="ff_composer">
            {/* PART 1 */}
            {step === 1 && (
              <StepOne
                formData={formData}
                handleChange={handleChange}
                nextStep={nextStep}
              />
            )}

            {/* PART 2 */}
            {step === 2 && (
              <StepTwo
                formData={formData}
                handleChange={handleChange}
                prevStep={prevStep}
                nextStep={nextStep}
              />
            )}

            {/* PART 3 */}
            {step === 3 && (
              <StepThree
                formData={formData}
                handleChange={handleChange}
                prevStep={prevStep}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            )}
          </div>
          <input
            type="hidden"
            name="email_subject"
            value={formData.email_subject}
          />
          <input
            type="hidden"
            name="email_applicant"
            value={formData.email_applicant}
          />
        </form>
      ) : (
        <div className="BreedRescueGrant__success active">
          <h1>Thank You! Your Response Has Reen Received!</h1>
          {acf.form_success_image && <img src={acf.form_success_image.url} />}
        </div>
      )}
    </div>
  );
};

export default BreedRescueGrant;
