import { connect } from 'react-redux';
import LandingPage from './index';
import { fetchPosts } from '../../actions/post_actions'

const mapStateToProps = (state) => {
    return {
        landingPage: Object.values(state.humane.landingPage)[0],
    };
};

const mapDispatchToProps = dispatch => ({
    fetchPosts: (slug)=>dispatch(fetchPosts(slug))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPage);
