import React from 'react';
// import '../../assets/stylesheets/test.scss'

class Generic extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){

        return (    
            <div>
                {this.props.acf.acf_fc_layout}
            </div>
        )
    }
}

export default Generic