import React, { useState } from 'react';

const StepTwo = ({ formData, handleChange, prevStep, nextStep }) => {
  const [errors, setErrors] = useState({});

  const validateStepTwo = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.app_grant_type.length === 0) {
      tempErrors['app_grant_type'] = 'Please select grant type';
      isValid = false;
    }

    if (formData.amount_of_funds_requested_up_to_5000_maximum.trim() === '') {
      tempErrors['amount_of_funds_requested_up_to_5000_maximum'] =
        'Please enter amount of funds requested';
      isValid = false;
    }

    if (formData.for_each_of === '') {
      tempErrors['for_each_of'] = 'Please select duration';
      isValid = false;
    }

    if (formData.app_special_project.trim() === '') {
      tempErrors['app_special_project'] =
        'Please enter special project description';
      isValid = false;
    }

    if (formData.app_anticipated_start_date.trim() === '') {
      tempErrors['app_anticipated_start_date'] = 'Please enter start date';
      isValid = false;
    }

    if (formData.app_anticipated_end_date.trim() === '') {
      tempErrors['app_anticipated_end_date'] = 'Please enter end date';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStepTwo()) {
      nextStep();
    }else {
        const firstErrorField = document.querySelector('.error');
        if (firstErrorField) {
          firstErrorField.scrollIntoView({ behavior: 'smooth' });
        }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section2">
      <h1>Breed Rescue Grant Application — Step 2 </h1>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h3>Grant Purpose</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_grant_type">
            Type of grant request
          </label>

          <ul>
            <li>
              <input
                type="checkbox"
                name="app_grant_type"
                id="freeform_app_grant_type_1"
                value="operating_support"
                onChange={handleChange}
                checked={formData.app_grant_type.includes('operating_support')}
              />

              <label htmlFor="freeform_app_grant_type_1">
                Operating Support
              </label>
            </li>
            <li>
              <input
                type="checkbox"
                name="app_grant_type"
                defaultValue="special_report"
                id="freeform_app_grant_type_2"
                value="special_report"
                onChange={handleChange}
                checked={formData.app_grant_type.includes('special_report')}
              />

              <label htmlFor="freeform_app_grant_type_2">Special Report</label>
            </li>
          </ul>
          {errors.app_grant_type && (
            <div className="error">{errors.app_grant_type}</div>
          )}

          <label className="field_label" htmlFor="freeform_"></label>

          <em>Please note: Grants are not awarded for capital improvements</em>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_16_amount_of_funds_requested_up_to_5000_maximum"
          >
            Amount of funds requested (up to $5,000 maximum)
          </label>

          <input
            type="text"
            name="amount_of_funds_requested_up_to_5000_maximum"
            defaultValue=""
            id="freeform_16_amount_of_funds_requested_up_to_5000_maximum"
            maxLength="150"
            onChange={handleChange}
            value={formData.amount_of_funds_requested_up_to_5000_maximum}
          />
          {errors.amount_of_funds_requested_up_to_5000_maximum && (
            <div className="error">
              {errors.amount_of_funds_requested_up_to_5000_maximum}
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_for_each_of">
            for each of
          </label>

          <select
            name="for_each_of"
            id="freeform_for_each_of"
            onChange={handleChange}
            value={formData.for_each_of}
          >
            <option defaultValue="1 year">1 year</option>
            <option defaultValue="2 years">2 years</option>
            <option defaultValue="3 years">3 years</option>
          </select>
          {errors.for_each_of && (
            <div className="error">{errors.for_each_of}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <h3>Special Project Grants</h3>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_app_special_project">
            Describe the Special Project for which you are requesting funds in
            50 words or less.
          </label>

          <textarea
            name="app_special_project"
            cols="50"
            rows="6"
            id="freeform_app_special_project"
            onChange={handleChange}
            value={formData.app_special_project}
          ></textarea>
          {errors.app_special_project && (
            <div className="error">{errors.app_special_project}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>

          <p>
            <strong>Anticipated project or program start and end dates:</strong>
          </p>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_app_anticipated_start_date"
          >
            Start Date
          </label>

          <input
            type="text"
            name="app_anticipated_start_date"
            id="freeform_app_anticipated_start_date"
            maxLength="150"
            className="hasDatepicker"
            onChange={handleChange}
            value={formData.app_anticipated_start_date}
          />
          {errors.app_anticipated_start_date && (
            <div className="error">{errors.app_anticipated_start_date}</div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_app_anticipated_end_date"
          >
            End Date
          </label>

          <input
            type="text"
            name="app_anticipated_end_date"
            id="freeform_app_anticipated_end_date"
            maxLength="150"
            className="hasDatepicker"
            onChange={handleChange}
            value={formData.app_anticipated_end_date}
          />
          {errors.app_anticipated_end_date && (
            <div className="error">{errors.app_anticipated_end_date}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>

          <a
            href
            defaultValue="Back"
            className="btn bg-black"
            onClick={prevStep}
            id="2"
          >
            Back
          </a>
        </div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>

          <a href className="btn pull-right" id="2" onClick={handleNext}>
            Continue To Part 3
          </a>
        </div>
      </div>
    </span>
  );
};

export default StepTwo;
