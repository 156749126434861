import axios from 'axios';

export const getPosts = (slug) => {
    let base_url = window.location.origin
    return axios({
        method: 'get',
        url: base_url.includes('localhost')? `http://localhost/wp-json/wp/v2/posts?slug=${slug}`: `${base_url}/wp-json/wp/v2/posts?slug=${slug}`,
        slug: slug
        // url: '/wp-json/wp/v2/posts',
        // data: {
        // //   title: this.form.title,
        // //   content: this.form.message,
        //   acf:{

        //     }
        // },
    })
}

