import * as APIUtil from '../util/posts_api_util';

export const RECEIVE_POSTS = 'RECEIVE_POSTS';
// export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS';

export const receivePosts = postsData => ({
    type: RECEIVE_POSTS,
    postsData: postsData.data
});

export const receiveErrors = errors => ({
    type: RECEIVE_SESSION_ERRORS,
    errors
});

export const fetchPosts = (slug) => dispatch => (
    APIUtil.getPosts(slug).then( (posts) => {
        (
        dispatch(receivePosts(posts))
    )}, err => (
        dispatch(receiveErrors(err.responseJSON))
    ))
)

export const login = user => dispatch => {
    
    // return (
    //     APIUtil.login(user).then(user => (
            
    //         dispatch(receiveCurrentUser(user))
    //     ), err => {
            
    //         return dispatch(receiveErrors(err.responseJSON))
    //     })
    // )
};

export const logout = () => dispatch => {
        
    // return (
    //         APIUtil.logout().then(user => {
                
    //             dispatch(logoutCurrentUser())
    //         })
// )
};
