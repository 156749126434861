import React from 'react';

class ContentBlock extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        return (
            <div className="contentBlock container">
                {this.props.acf.content_block_section_title && (
                    <h2 className="contentBlock__title">{this.props.acf.content_block_section_title}</h2>
                )}

                {this.props.acf.content_block_section_description && (
                    <p dangerouslySetInnerHTML={{__html: this.props.acf.content_block_section_description }} />
                )}
                <div className="contentBlock__flex-wrap">
                    {this.props.acf.content_block.map(content_block=>{
                        return (
                        <div className="contentBlock__container">
                            <img key={content_block.image.url} className="contentBlock__img" src={content_block.image.url} />
                            <div className="contentBlock__content-box">
                                <h1 className="contentBlock__box-title">{content_block.title_link.title}</h1>
                                <span dangerouslySetInnerHTML={{__html: content_block.content_block_description}} />
                                <a className="contentBlock__button btnn" href={content_block.button_link.url}>{content_block.button_link.title}</a>
                            </div>
                        </div>
                        )
                    })}
                </div>    
            </div>
        )
    }
}

export default ContentBlock