import React, { Component, useEffect } from 'react'
import $ from 'jquery';

export class MakeLegacyGift extends Component {

    constructor(props) {
        super(props);
    }

    nextForm(e){
        let section = parseInt(e.target.id)

        if (section !== 4) {    
            $(`#section${section}`).toggleClass('active')
            $(`#section${section + 1}`).toggleClass('active')
        } else {
            $(`#section${section}`).toggleClass('active')
            $(`#section1`).toggleClass('active')
        }
    }

    prevForm(e){
        let section = parseInt(e.target.id)

        $(`#section${section}`).toggleClass('active')
        $(`#section${section - 1}`).toggleClass('active')
    }

    preventFormSubmission(e) {
        if (!e) return
        e.preventDefault();

        function responseReceived () {
            $(`#makeLegacyGift`).hide()
            $('.makeLegacyGift__success').toggleClass('active')
        }

        var serializedData = new FormData(e.target);
        serializedData.append('action','handle_insurance_form_submit_ajax');

        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php', 
            dataType: "json",
            data: serializedData,
            cache: false,
            processData: false,
            contentType: false,
            success: function( response ){ 
                responseReceived()

            },
            error: function(error){ 
                console.log(error) 
            }                
        })
    }

    renderForm(){
        return (
        <form onSubmit={this.preventFormSubmission.bind(this)} id={`makeLegacyGift`} >
            <input type="hidden" name="freeform_multipage_hash" defaultValue={"3ace6f9a68d2ad0f127077e35fcb9139"} />
            <input type="hidden" name="params_id" defaultValue={"11190042"} />
		 					  
            <div class="ff_composer makeLegacyGift">
                <h1>Make A Legacy Gift </h1>
                <div class="line">
                    <div class="unit size1of1">
                        <label class="field_label" for="freeform_"></label>
                        Make A Legacy Gift
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                    <label class="field_label" for="freeform_"></label>
                    If you'd like to learn more about legacy giving and keeping the AKC Humane Fund in mind as you plan for the divestment of your assets, please fill out the contact form below.
                        
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                        <label class="field_label" for="freeform_app_ref_name1">
                            Full Name			            	 
                        </label>
                                                            
                        <input type="text" name="app_ref_name1" value="" id="freeform_app_ref_name1" maxlength="150" />
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                        <label class="field_label" for="freeform_email">
                            Email Address			            	 
                        </label>
                                                            
                        <input type="text" name="email" value="" id="freeform_email" maxlength="150" />
                        
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                        <label class="field_label" for="freeform_app_phone">
                            Phone Number			            	 
                        </label>
                        <input type="text" name="app_phone" value="" id="freeform_app_phone" maxlength="150" />
                        
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                    <label class="field_label" for="freeform_preferred_method_for_contact">
                        Preferred Method For Contact	                            	                        
                    </label>
                    
                    <ul>
                        <li>
                            <input type="radio" name="preferred_method_for_contact" id="freeform_preferred_method_for_contact_1" value="Phone" />
                            <label for="freeform_preferred_method_for_contact_1">Phone</label>
                        </li>
                        <li>
                            <input type="radio" name="preferred_method_for_contact" id="freeform_preferred_method_for_contact_2" value="Email" />
                            <label for="freeform_preferred_method_for_contact_2">Email</label>
                        </li>
                        <li>
                            <input type="radio" name="preferred_method_for_contact" id="freeform_preferred_method_for_contact_3" value="Either" />
                            <label for="freeform_preferred_method_for_contact_3">Either</label>
                        </li>
                    </ul>
                        
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                        <label class="field_label" for="freeform_are_you_a_robot">
                            Are you a robot?	                            	                        
                        </label>
                    
                        <select name="are_you_a_robot" id="freeform_are_you_a_robot">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Maybe">Maybe</option>
                        </select>
                        
                    </div>
                </div>
                    
                <div class="line">
                    <div class="unit size1of1">
                        <label class="field_label" for="freeform_"></label>

                        <input type="submit" name="submit" value="Submit" class="btn pull-right" />
                    </div>
                </div>
            </div>
            <input type="hidden" name="email_subject" value="New Application - Make Legacy Gift" />  
            <input type="hidden" name="email_applicant" value="Make Legacy Gift" />      			  	  			  
        </form>
        )
    }

    render() {
        let acf = this.props.acf
        
        return (
            <div className={"container"}>
                {this.renderForm()}

                <div className={"makeLegacyGift__success"}>
                    <h1>Thank You! Your Response Has Reen Received!</h1>
                    {acf.form_success_image && 
                        <img src={acf.form_success_image.url} />
                    }
                </div>
            </div>
        )
    }
}

export default MakeLegacyGift