import React, { useState } from 'react';

const StepOne = ({ formData, handleChange, nextStep }) => {
  const [errors, setErrors] = useState({});

  const validateStepOne = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.full_legal_name_of_applicant.trim() === '') {
      isValid = false;
      tempErrors['full_legal_name_of_applicant'] = 'Please enter full legal';
    }

    if (formData.app_legal_structure.trim() === '') {
      isValid = false;
      tempErrors['app_legal_structure'] = 'Please enter legal structure';
    }

    if (formData.employee_identification_number_ein.trim() === '') {
      isValid = false;
      tempErrors['employee_identification_number_ein'] =
        'Please enter employee identification number (EIN)';
    }

    if (formData.app_contact_name.trim() === '') {
      isValid = false;
      tempErrors['app_contact_name'] = 'Please enter contact name';
    }

    if (formData.app_contact_title.trim() === '') {
      isValid = false;
      tempErrors['app_contact_title'] = 'Please enter contact title';
    }

    if (formData.mailing_address.trim() === '') {
      isValid = false;
      tempErrors['mailing_address'] = 'Please enter mailing address';
    }

    if (formData.app_city.trim() === '') {
      isValid = false;
      tempErrors['app_city'] = 'Please enter city';
    }

    if (formData.app_state.trim() === '') {
      isValid = false;
      tempErrors['app_state'] = 'Please enter state';
    }

    if (formData.app_ref_zip1.trim() === '') {
      isValid = false;
      tempErrors['app_ref_zip1'] = 'Please enter zip code';
    }

    if (formData.app_phone.trim() === '') {
      isValid = false;
      tempErrors['app_phone'] = 'Please enter phone number';
    }

    if (formData.email.trim() === '') {
      isValid = false;
      tempErrors['email'] = 'Please enter email address';
    }

    if (formData.app_website.trim() === '') {
      isValid = false;
      tempErrors['app_website'] = 'Please enter website';
    }

    if (formData.app_geography.trim() === '') {
      isValid = false;
      tempErrors['app_geography'] = 'Please enter geographic area served';
    }

    if (formData.app_year_founded.trim() === '') {
      isValid = false;
      tempErrors['app_year_founded'] = 'Please enter year founded';
    }

    if (formData.app_employees.trim() === '') {
      isValid = false;
      tempErrors['app_employees'] = 'Please enter number of employees';
    }

    if (formData.app_volunteers.trim() === '') {
      isValid = false;
      tempErrors['app_volunteers'] = 'Please enter number of volunteers';
    }

    if (formData.app_fiscal_year.trim() === '') {
      isValid = false;
      tempErrors['app_fiscal_year'] =
        'Please enter most recent fiscal year end (DD/MM)';
    }

    if (!formData.is_your_organization) {
      isValid = false;
      tempErrors['is_your_organization'] = 'Please select organization type';
    }

    if (
      !formData.have_you_applied_for_a_grant_from_the_akc_humane_fund_previously
    ) {
      isValid = false;
      tempErrors[
        'have_you_applied_for_a_grant_from_the_akc_humane_fund_previously'
      ] = 'Please select yes or no';
    }

    if (
      formData.have_you_applied_for_a_grant_from_the_akc_humane_fund_previously ===
        'yes' &&
      formData.app_apply_year.trim() === ''
    ) {
      isValid = false;
      tempErrors['app_apply_year'] = 'Please enter the year you applied';
    }

    if (!formData.were_you_awarded_a_grant_from_the_akc_humane_fund) {
      isValid = false;
      tempErrors['were_you_awarded_a_grant_from_the_akc_humane_fund'] =
        'Please select yes or no';
    }

    if (
      formData.were_you_awarded_a_grant_from_the_akc_humane_fund === 'yes' &&
      formData.if_yes_what_year_did_you_submit_an_application.trim() === ''
    ) {
      isValid = false;
      tempErrors['if_yes_what_year_did_you_submit_an_application'] =
        'Please enter the year you received the award';
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStepOne()) {
      nextStep();
    } else {
      const firstErrorField = document.querySelector('.error');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <span className={'BreedRescueGrant__form__span active'} id="section1">
      <h1>Women’s Shelters Grant </h1>
      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>
          Women’s Shelters Grant
          <label className="field_label" htmlFor="freeform_"></label>
          The AKC Humane Fund is proud to be the leading organization that
          provides financial assistance to domestic abuse shelters that accept
          pets. Our grants make it easier for women’s shelters to welcome pets
          and help break the cycle of domestic abuse. Apply for a grant and tell
          us how you are helping pet owners at your facility.
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_"></label>
          <h3>Qualifications &amp; Eligibility</h3>
          <li>
            Applicant must be (A) a not-for-profit organization that provides
            temporary or permanent housing for victims of domestic abuse and
            their pets, or (B), a not-for-profit organization that provides
            temporary or permanent housing for pets of victims of domestic abuse
            and has a formal, established working relationship with at least one
            not-for-profit shelter for victims of domestic abuse. Applicants in
            category (A) are preferred.
          </li>
          <li>
            Applicants representing a pet shelter (See category B above) must
            provide a letter of referral from at least one Shelter for Victims
            of Domestic Abuse that the applicant assists. The letter must be on
            the Domestic Abuse Shelter’s letterhead, signed by the Executive
            Director of the Domestic Abuse Shelter and detail the extent of the
            relationship between your organizations.
          </li>
          <li>
            AKC Humane Fund Women’s Shelter Grants are awarded for essential
            operational support relating to the housing of pets or capital
            improvements specifically for the housing and maintenance of pets.
          </li>
          <li>
            AKC Humane Fund Rescue Grants may not be used for fundraising
            purposes.
          </li>
          <li>
            An applicant may not apply for an AKC Humane Fund Grant if it has
            been awarded a grant from the AKC Humane Fund twice in the five
            calendar year period immediately preceding the application deadline.
          </li>
          <li>
            Applicants that are awarded grants must comply with a reporting
            process as indicated by the AKC Humane Fund.
          </li>
          <p></p>
          <h3>Application Instructions</h3>
          Submitted applications must include the following materials, in this
          order:
          <li>
            Completed Grant Application form with additional sheets attached as
            needed to answer questions. The original form is required and must
            be signed and dated.
          </li>
          <li>
            Financial information regarding your organization, including the
            most recent financial statements (preferably audited), total current
            budget for the last completed fiscal year and for the current fiscal
            year of the organization, and a list detailing the principal sources
            of ongoing annual support for the organization.
          </li>
          <li>
            Copy of the IRS Determination Letter confirming Section 501 (c)(3)
            status (if applicable).
          </li>
          <p></p>
          <p>
            If you'd prefer to fill out a paper version of this grant
            application, please use the PDF form located{' '}
            <a href="https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2019/09/18151540/Womens-Shelter-Grant-App-2019.pdf">
              here
            </a>
          </p>
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label
            className="field_label"
            htmlFor="freeform_full_legal_name_of_applicant"
          >
            Full Legal Name of Applicant
          </label>

          <input
            type="text"
            name="full_legal_name_of_applicant"
            id="freeform_full_legal_name_of_applicant"
            maxLength="150"
            onChange={handleChange}
            value={formData.full_legal_name_of_applicant}
          />
          {errors.full_legal_name_of_applicant && (
            <div className="error">{errors.full_legal_name_of_applicant}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_legal_structure">
            Legal Structure
          </label>

          <input
            type="text"
            name="app_legal_structure"
            id="freeform_app_legal_structure"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_legal_structure}
          />
          {errors.app_legal_structure && (
            <div className="error">{errors.app_legal_structure}</div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_employee_identification_number_ein"
          >
            Employee Identification Number (EIN)
          </label>

          <input
            type="text"
            name="employee_identification_number_ein"
            id="freeform_employee_identification_number_ein"
            maxLength="150"
            onChange={handleChange}
            value={formData.employee_identification_number_ein}
          />
          {errors.employee_identification_number_ein && (
            <div className="error">
              {errors.employee_identification_number_ein}
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_contact_name">
            Contact Name
          </label>

          <input
            type="text"
            name="app_contact_name"
            id="freeform_app_contact_name"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_contact_name}
          />
          {errors.app_contact_name && (
            <div className="error">{errors.app_contact_name}</div>
          )}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_contact_title">
            Contact Title
          </label>

          <input
            type="text"
            name="app_contact_title"
            id="freeform_app_contact_title"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_contact_title}
          />
          {errors.app_contact_title && (
            <div className="error">{errors.app_contact_title}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of1">
          <label className="field_label" htmlFor="freeform_mailing_address">
            Mailing Street Address
          </label>

          <input
            type="text"
            name="mailing_address"
            id="freeform_mailing_address"
            maxLength="150"
            onChange={handleChange}
            value={formData.mailing_address}
          />
          {errors.mailing_address && (
            <div className="error">{errors.mailing_address}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_city">
            City
          </label>

          <input
            type="text"
            name="app_city"
            id="freeform_app_city"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_city}
          />
          {errors.app_city && <div className="error">{errors.app_city}</div>}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_state">
            State
          </label>

          <input
            type="text"
            name="app_state"
            id="freeform_app_state"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_state}
          />
          {errors.app_state && <div className="error">{errors.app_state}</div>}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_ref_zip1">
            Zip Code
          </label>

          <input
            type="text"
            name="app_ref_zip1"
            id="freeform_app_ref_zip1"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_ref_zip1}
          />
          {errors.app_ref_zip1 && (
            <div className="error">{errors.app_ref_zip1}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_phone">
            Phone Number
          </label>

          <input
            type="text"
            name="app_phone"
            id="freeform_app_phone"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_phone}
          />
          {errors.app_phone && <div className="error">{errors.app_phone}</div>}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_email">
            Email Address
          </label>

          <input
            type="text"
            name="email"
            id="freeform_email"
            maxLength="150"
            onChange={handleChange}
            value={formData.email}
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_website">
            Website
          </label>

          <input
            type="text"
            name="app_website"
            id="freeform_app_website"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_website}
          />
          {errors.app_website && (
            <div className="error">{errors.app_website}</div>
          )}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_geography">
            Geographic Area Served
          </label>

          <input
            type="text"
            name="app_geography"
            id="freeform_app_geography"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_geography}
          />
          {errors.app_geography && (
            <div className="error">{errors.app_geography}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_year_founded">
            Year Founded
          </label>

          <input
            type="text"
            name="app_year_founded"
            id="freeform_app_year_founded"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_year_founded}
          />
          {errors.app_year_founded && (
            <div className="error">{errors.app_year_founded}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_employees">
            Number of Employees
          </label>

          <input
            type="text"
            name="app_employees"
            id="freeform_app_employees"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_employees}
          />
          {errors.app_employees && (
            <div className="error">{errors.app_employees}</div>
          )}
        </div>

        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_volunteers">
            Number of Volunteers
          </label>
          <input
            type="text"
            name="app_volunteers"
            id="freeform_app_volunteers"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_volunteers}
          />
          {errors.app_volunteers && (
            <div className="error">{errors.app_volunteers}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of3">
          <label className="field_label" htmlFor="freeform_app_fiscal_year">
            Most recent fiscal year end (DD/MM)
          </label>

          <input
            type="text"
            name="app_fiscal_year"
            id="freeform_app_fiscal_year"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_fiscal_year}
          />
          {errors.app_fiscal_year && (
            <div className="error">{errors.app_fiscal_year}</div>
          )}
        </div>

        <div className="unit size1of3"></div>

        <div className="unit size1of3"></div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_is_your_organization"
          >
            1. Is your organization
          </label>

          <ul>
            <li>
              <input
                type="radio"
                name="is_your_organization"
                id="freeform_is_your_organization_1"
                checked={
                  formData.is_your_organization === 'A Domestic Abuse Shelter'
                }
                onChange={handleChange}
                value="A Domestic Abuse Shelter"
              />
              <label htmlFor="freeform_is_your_organization_1">
                (A) A Domestic Abuse Shelter
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="is_your_organization"
                id="freeform_is_your_organization_2"
                checked={
                  formData.is_your_organization === 'An Animal Shelter only'
                }
                onChange={handleChange}
                value="An Animal Shelter only"
              />
              <label htmlFor="freeform_is_your_organization_2">
                (B) An Animal Shelter only?
              </label>
            </li>
          </ul>
          {errors.is_your_organization && (
            <div className="error">{errors.is_your_organization}</div>
          )}
        </div>

        <div className="unit size1of2"></div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_2_have_you_applied_for_a_grant_from_the_akc_humane_fund_previously"
          >
            2. Have you applied for a grant from the AKC Humane Fund previously?
          </label>
          <ul>
            <li>
              <input
                type="radio"
                name="have_you_applied_for_a_grant_from_the_akc_humane_fund_previously"
                id="freeform_2_have_you_applied_for_a_grant_from_the_akc_humane_fund_previously_1"
                checked={
                  formData.have_you_applied_for_a_grant_from_the_akc_humane_fund_previously ===
                  'yes'
                }
                onChange={handleChange}
                value="yes"
              />
              <label htmlFor="freeform_2_have_you_applied_for_a_grant_from_the_akc_humane_fund_previously_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="have_you_applied_for_a_grant_from_the_akc_humane_fund_previously"
                id="freeform_2_have_you_applied_for_a_grant_from_the_akc_humane_fund_previously_2"
                checked={
                  formData.have_you_applied_for_a_grant_from_the_akc_humane_fund_previously ===
                  'no'
                }
                onChange={handleChange}
                value="no"
              />
              <label htmlFor="freeform_2_have_you_applied_for_a_grant_from_the_akc_humane_fund_previously_2">
                No
              </label>
            </li>
          </ul>
          {errors.have_you_applied_for_a_grant_from_the_akc_humane_fund_previously && (
            <div className="error">
              {
                errors.have_you_applied_for_a_grant_from_the_akc_humane_fund_previously
              }
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label className="field_label" htmlFor="freeform_app_apply_year">
            If yes, in what year did you apply? (YYYY)
          </label>

          <input
            type="text"
            name="app_apply_year"
            id="freeform_app_apply_year"
            maxLength="150"
            onChange={handleChange}
            value={formData.app_apply_year}
          />
          {errors.app_apply_year && (
            <div className="error">{errors.app_apply_year}</div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_3_were_you_awarded_a_grant_from_the_akc_humane_fund"
          >
            3. Were you awarded a grant from the AKC Humane Fund?
          </label>
          <ul>
            <li>
              <input
                type="radio"
                name="were_you_awarded_a_grant_from_the_akc_humane_fund"
                id="freeform_3_were_you_awarded_a_grant_from_the_akc_humane_fund_1"
                checked={
                  formData.were_you_awarded_a_grant_from_the_akc_humane_fund ===
                  'yes'
                }
                onChange={handleChange}
                value="yes"
              />
              <label htmlFor="freeform_3_were_you_awarded_a_grant_from_the_akc_humane_fund_1">
                Yes
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="were_you_awarded_a_grant_from_the_akc_humane_fund"
                id="freeform_3_were_you_awarded_a_grant_from_the_akc_humane_fund_2"
                checked={
                  formData.were_you_awarded_a_grant_from_the_akc_humane_fund ===
                  'no'
                }
                onChange={handleChange}
                value="no"
              />
              <label htmlFor="freeform_3_were_you_awarded_a_grant_from_the_akc_humane_fund_2">
                No
              </label>
            </li>
          </ul>
          {errors.were_you_awarded_a_grant_from_the_akc_humane_fund && (
            <div className="error">
              {errors.were_you_awarded_a_grant_from_the_akc_humane_fund}
            </div>
          )}
        </div>

        <div className="unit size1of2">
          <label
            className="field_label"
            htmlFor="freeform_if_yes_what_year_did_you_submit_an_application"
          >
            If yes, what year did you receive the award? (YYYY)
          </label>

          <input
            type="text"
            name="if_yes_what_year_did_you_submit_an_application"
            id="freeform_if_yes_what_year_did_you_submit_an_application"
            maxLength="150"
            onChange={handleChange}
            value={formData.if_yes_what_year_did_you_submit_an_application}
          />
          {errors.if_yes_what_year_did_you_submit_an_application && (
            <div className="error">
              {errors.if_yes_what_year_did_you_submit_an_application}
            </div>
          )}
        </div>
      </div>

      <div className="line">
        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4"></div>

        <div className="unit size1of4">
          <label className="field_label" htmlFor="freeform_"></label>
          <a href className="btn pull-right" id="1" onClick={handleNext}>
            Continue To Part 2
          </a>
        </div>
      </div>
    </span>
  );
};

export default StepOne;
