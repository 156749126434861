import React, { Component, useEffect } from 'react'
import Generic from '../Generic/index'
import FlexibleContent from '../FlexibleContent/index'
import Hero from '../Hero/index'
import HeroCarousel from '../HeroCarousel/index'
import ContentBlock from '../ContentBlock/index'
import ContentBlock3x3 from '../ContentBlock3x3/index'
import Forms from '../Forms/index'
export class LandingPage extends Component {

    constructor(props) {
        super(props);
    }

    state = {
       layouts: [],
       slug: this.props.slug,
    }

    captString(string) {
        if (!string) return 

        // wordpress doesn't capitalize hyphenated strings, so we can ignore those 
        if (string.includes('-')) return string 

        return string[0].toUpperCase() + string.slice(1) // eggs => Eggs
    }

    componentDidUpdate (prevProps) {
        if (prevProps.slug !== this.props.slug) {
            this.props.fetchPosts(this.props.slug)
        }
        let landingPage = this.props.landingPage

        if (prevProps.landingPage !== landingPage && landingPage.length > 0 
            && ( this.captString(landingPage[0].slug) == this.props.slug.slice(1)  )) { // if the response from the above fetchPosts's slug is the same as our props.slug...
                
            this.setState({ 
                landingPage: this.props.landingPage,
                layouts: this.props.landingPage[0].acf.content,
            }) 
        } 
    }

    filterLayouts(layouts) {
        let components = []

        for (let i = 0; i < layouts.length; i++) {
            let layout = layouts[i].acf_fc_layout
            let singleComponent = []

            layout.split('_').map((word)=>{
                singleComponent.push(word[0].toUpperCase() + word.substring(1))
            })
            // we'll use this string to key into component map
            let singleComponentString = singleComponent.join('')

            // need to redclare the map each loop to passdown the props appropriately 
            let componentMap = {
                "Generic": <Generic key={i} acf={layouts[i]}/>, // finish updating this
                "Description": <FlexibleContent key={i} acf={layouts[i]} />,
                "Hero": <Hero key={i} acf={layouts[i]} />,
                "HeroCarousel": <HeroCarousel key={i} acf={layouts[i]} />,
                "ContentBlock": <ContentBlock key={i} acf={layouts[i]} />,
                "ContentBlock3x3": <ContentBlock3x3 key={i} acf={layouts[i]} />,
                "FormOptions": <Forms key={i} acf={layouts[i]}/>,
            }
            
            // collect formatted Component Names with ACF Props into an array
            let component = componentMap[singleComponentString] ? componentMap[singleComponentString] : componentMap["Generic"]

            components.push(
                component
            )
        }   

        // call components based on Name and pass respective ACF props
        return components
    }

    render () {
        let layouts = this.state.layouts

        return (
        <div>
            {layouts && layouts.length > 0 && (
                this.filterLayouts(layouts)
            )}
        </div>
        )
    }   
}
export default LandingPage
