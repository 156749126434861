import { combineReducers } from '@reduxjs/toolkit'
import landingPageReducer from './landingPageReducer'
import footerReducer from './footerReducer'
import headerReducer from './headerReducer'
import navMenuReducer from './navMenuReducer'


const rootReducer = combineReducers({
  humane: combineReducers({
      landingPage: landingPageReducer,
      header: headerReducer,
      footer: footerReducer,
      navMenu: navMenuReducer,
  })
})

export default rootReducer
