import React from 'react';
import Slider from '../Slick/index'

class HeroCarousel extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        return (    
            <div className="heroCarousel">
                <Slider>
                    {this.props.acf.events.map(event=>{
                        return (
                        <div className="heroCarousel__container">
                            <img key={event.image.url} className="heroCarousel__img" src={event.image.url} />
                            <div className={`heroCarousel__content-box ${event.carousel_text_orient} ${event.carousel_text_color}`}>
                                <h1>{event.title}</h1>
                                <span dangerouslySetInnerHTML={{__html: event.subtitle}} />
                                {event.link && (
                                    <a className="heroCarousel__button btnn" href={event.link.url}>{event.link.title}</a>
                                )}
                            </div>
                        </div>
                        )
                    })}
                </Slider>
            </div>
        )
    }
}

export default HeroCarousel